var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "update-case-btn" }, [
    _c(
      "button",
      {
        staticClass: "button-secondary",
        attrs: { disabled: _vm.isReadOnly },
        on: { click: _vm.onButtonClick }
      },
      [_c("fmmp-i18n", { attrs: { text: "Update case" } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }