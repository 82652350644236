<template>
    <div class="specification-filter">
        <ul>
            <li v-for="option in optionsList" :key="option"
                :class="['specification-filter__option', { selected: option === selectedOption }]"
                @click="selectedOption = option">
                {{ getTranslation(option) }}
            </li>
        </ul>
    </div>
</template>

<script>
import { getTranslation } from "../../../../common/partFinderCorporate.helpers";
import { PART_DESCRIPTION_ATTRIBUTE_NAME } from "../../../../common/specification-search/constants";

const ALL_OPTION = "all";

export default Vue.component("driv-corp-specification-results-filter", {
    data() {
        return {
            selectedOption: ALL_OPTION,
        };
    },
    computed: {
        optionsList() {
            const { options, column, isNumeric } = this.params || {};
            /* ColDef contains updated filters content, but options from filter params 
                look like they're never updated, so they are used as a fallback */
            const relevantOptions = column?.colDef?.filterParams?.options || options;
            if (!relevantOptions?.length) return [];

            const sortedOptions = relevantOptions.sort(isNumeric ? (a, b) => a - b : undefined);
            return [ALL_OPTION, ...sortedOptions];
        },
    },
    watch: {
        selectedOption(option) {
            const colId = this.params?.colDef?.field || "";
            const [name, uom] = colId.split("_");

            if (PART_DESCRIPTION_ATTRIBUTE_NAME === colId) {
                const element = document.querySelector('[col-id="' + colId + '"]');

                if (option === ALL_OPTION) {
                    setTimeout(() => element.classList.remove('ag-header-cell-filtered'), 0);
                } else {
                    setTimeout(() => element.classList.add('ag-header-cell-filtered'), 0);
                }
                return;
            }

            this.params?.filterChangedCallback({
                selectedFilter: {
                    name,
                    value: option === ALL_OPTION ? "" : option,
                    ...(uom ? { uom } : {}),
                },
                colId,
            });
        },
    },
    methods: {
        getTranslation,
        doesFilterPass() {
            return true;
        },
        isFilterActive() {
            return this.selectedOption !== ALL_OPTION;
        },
        getModel() {
            return { value: this.selectedOption };
        },
        setModel(model = {}) {
            this.selectedOption = model?.value || ALL_OPTION;
        },
    },
});
</script>
