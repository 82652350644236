<template>
    <div class="torque-details-page">
        <div class="back-to-results-link">
            <a @click="deleteBaseAndDocIdsFromUrl">
                {{ getTranslation("Back To Search Results") }}
            </a>
        </div>
        <div class="torque-details">
            <div class="torque-details__image" v-if="isImageShown">
                <img :src="imgSrc" alt="Torque Specification image" />
            </div>
            <div :class="['torque-details__info', { 'no-image': !isImageShown }]">
                <div class="info-header">
                    <div v-if="isCylinderHead" class="logos">
                        <div v-if="cylinderPage1stLogo">
                            <img :src="cylinderPage1stLogo" :alt="cylinderPage1stLogo" />
                        </div>
                        <div v-if="cylinderPage2ndLogo">
                            <img :src="cylinderPage2ndLogo" :alt="cylinderPage2ndLogo" />
                        </div>
                    </div>
                    <div v-else class="logos">
                        <div v-if="hubPage1stLogo">
                            <img :src="hubPage1stLogo" :alt="hubPage1stLogo" />
                        </div>
                        <div v-if="hubPage2ndLogo">
                            <img :src="hubPage2ndLogo" :alt="hubPage2ndLogo" />
                        </div>
                    </div>
                    <fmmp-i18n text="Torque Specs" />
                </div>
                <div class="applied-filters">
                    <span>{{ getTranslation("Applied Filters") }}:</span>
                    <p>
                        <span>{{ getTranslation("Year") }}:</span>
                        {{ ymmFromUrl.year }}
                    </p>
                    <p>
                        <span>{{ getTranslation("Make") }}:</span>
                        {{ ymmFromUrl.make }}
                    </p>
                    <p>
                        <span>{{ getTranslation("Model") }}:</span>
                        {{ ymmFromUrl.model }}
                    </p>
                    <p v-if="isCylinderHead">
                        <span>{{ getTranslation("Engine") }}:</span>
                        {{ ymmFromUrl.engine }}
                    </p>
                </div>
                <div v-if="!error" :class="['notes', { bordered: isCylinderHead && !error }]">
                    <div v-if="isCylinderHead">
                        <fmmp-i18n text="Torque Specifications" class="title" />
                        <div class="steps">
                            <template v-for="(note, i) in procNotes">
                                <p class="proc-notes" :key="note + i">{{ note }}</p>
                            </template>
                        </div>
                        <span class="remark">{{ torqueSpecRemark }}</span>
                    </div>
                    <div v-else>
                        <template v-for="(note, i) in notesArrayForHubAndWheel">
                            <p :key="note + i">{{ note }}</p>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    createUrlToGoToSearchResults,
    getTranslation,
} from "../../../../common/partFinderCorporate.helpers";
import { getQsParams } from "../helpers";
import { SPEC_PROPS } from "./constants";

const { LOOK_UP_IDS } = Vue.PartFinder;

export default Vue.component("driv-corp-torque-details", {
    props: {
        hubPage1stLogo: {
            type: String,
            default: "",
        },
        hubPage2ndLogo: {
            type: String,
            default: "",
        },
        cylinderPage1stLogo: {
            type: String,
            default: "",
        },
        cylinderPage2ndLogo: {
            type: String,
            default: "",
        },
        torqueSpecRemark: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            imgSrc: null,
            specifications: [],
            error: false,
            notesArrayForHubAndWheel: [],
            procNotes: [],
        };
    },
    computed: {
        isCylinderHead() {
            const { contentSilos } = getQsParams();
            return contentSilos.value === LOOK_UP_IDS.TORQUE_CYLINDER_HEAD_CONTENT;
        },
        isImageShown() {
            const { documentId } = getQsParams();
            return !!documentId && this.isCylinderHead;
        },
        ymmFromUrl() {
            const { year, make, model, engine } = getQsParams();
            return {
                year: year.label,
                make: make.label,
                model: model?.label,
                engine: engine?.label,
            };
        },
        currentSpecsAppObject() {
            const { specAppId } = getQsParams();
            const appId = +specAppId;
            return this.specifications.find((app) => appId === app.ApplicationID);
        },
    },
    methods: {
        getTranslation,
        getTorqueDetailsImage() {
            const { documentId, baseId } = getQsParams();

            if (documentId) {
                return Vue.CatalogApi.CatalogApiService.getTorqueDetails({
                    baseId,
                    documentId,
                }).then((res) => (this.imgSrc = "data:image/jpg;base64," + res));
            }
        },
        deleteBaseAndDocIdsFromUrl() {
            const path = window.location.pathname.replace(".html", "");
            const { baseId, documentId, specAppId, ...restParams } = getQsParams();
            const url = createUrlToGoToSearchResults({ queryObj: restParams, path });

            Vue.Global.Analytics.trackEvent(
                "torque-details",
                "click-back-to-search-results",
                url,
                null,
                {
                    callback: function () {
                        window.location.assign(url);
                    },
                },
            );
        },
        getSpecifications() {
            const { engine, baseId, contentSilos, qualifierId } = getQsParams();

            Vue.CatalogApi.CatalogApiService.getTorqueSpecification({
                baseId,
                contentSilos: contentSilos.value,
                engineId: engine?.value,
                qualifierId,
            })
                .then((res) => {
                    this.specifications = res[SPEC_PROPS.SPEC][SPEC_PROPS.APPS];

                    if (this.isCylinderHead) {
                        this.procNotes = this.getProcNotes();
                    } else {
                        this.notesArrayForHubAndWheel = this.getNotesArrayForHubAndWheel();
                    }
                })
                .catch(() => (this.error = true));
        },
        getNotesArrayForHubAndWheel() {
            let allNotes = [];

            this.currentSpecsAppObject.PositionList?.forEach((position) => {
                const items = this.currentSpecsAppObject.Items?.map(
                    (item) => `${position.Name} (${item?.UnitOfMeasure}) ${item.Value}`,
                );

                const notes = this.currentSpecsAppObject.Notes?.map(
                    (note) => `${position.Name} ${note?.Text}`,
                );

                allNotes = [...allNotes, ...items, ...notes];
            });

            return [...allNotes];
        },
        getProcNotes() {
            const procs = this.currentSpecsAppObject.ServiceProcedures?.[0]?.ProcNote?.map(
                (note) => `${note.Proc}: ${note.Torque || ""}${note.Degrees || ""}`,
            );

            const notes = this.currentSpecsAppObject.Notes?.map(
                (note) => `${getTranslation("NOTE")}: ${note?.Text}`,
            );

            return [...procs, ...notes];
        },
    },
    created() {
        this.getTorqueDetailsImage();
        this.getSpecifications();
    },
});
</script>
