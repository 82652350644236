import "./PartResultsPage.vue";
import "./Pagination.vue";
import "./filtering-table/EngineSearchFilteringTable.vue";
import "./list-view/PartsListViewItem.vue";
import "./list-view/PartsListViewItemGpi.vue";
import "./list-view/PartsListView.vue";
import "./grid-view/PartsGridViewItem.vue";
import "./grid-view/PartsGridViewItemGpi.vue";
import "./grid-view/PartsGridView.vue";
import "./PartsResultsFilters.vue";
import "./part-number-results/PartNumberResults.vue";
import "./part-number-results/PartTab.vue";
import "./part-number-results/InterchangesTab.vue";
import "./shared/PartNameSection.vue";
import "./shared/PartNameSectionGpi.vue";
import "./shared/EmptyPartResults.vue";
import "./shared/SelectedPartsAmountGpi.vue";
import "./shared/NotificationPopup.vue";
import "./shared/UpdateCaseButton.vue";
import "./grid-view/QuickDetailsGridView.vue";
import "./specification-results/SpecificationResults.vue";
import "./specification-results/RangeFilterBlock.vue";
import "./specification-results/InfoModal.vue";
import "./u-joint-results/uJointResults.vue";
import "./u-joint-results/BearingDiametersPage.vue";
import "./u-joint-results/UJointCommonPageLayout.vue";
import "./u-joint-results/uJointSelectedDiameterInfoPage.vue";
import "./u-joint-results/uJointTypesPage.vue";
import "./diagram-results/InteractiveDiagram.vue";
import "./diagram-results/InteractiveDiagramModal.vue";
import "./diagram-results/DiagramCarousel.vue";
import "./torque-results/TorqueResults.vue";
import "./torque-results/TorqueYMMSearch.vue";
import "./torque-results/TorqueResultsTable.vue";
import "./torque-results/TorqueDetails.vue";

if (document.querySelector('[data-component="driv-part-finder-part-results"]')) {
    new Vue({
        el: '[data-component="driv-part-finder-part-results"]',
    });
}
