<template>
    <div class="driv-corp-list-pagination">
        <fmmp-i18n text="page" />
        <input
            type="text"
            v-model.trim="curPage"
            @change="onCurPageChange"
        />
        <fmmp-i18n text="of" />
        {{ totalPages }}
        <button
            :disabled="curPage === 1"
            @click="() => onPageChange(curPage - 1)"
        >
            <i class="fa fa-angle-left"></i>
        </button>
        <button
            :disabled="curPage === totalPages"
            @click="() => onPageChange(curPage + 1)"
        >
            <i class="fa fa-angle-right"></i>
        </button>
    </div>
</template>

<script>
export default Vue.component("driv-corp-list-pagination", {
    props: {
        savedPage: {
            type: Number,
            default: 1,
        },
        totalPages: {
            type: Number,
            default: 1,
        },
        onPageChange: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            curPage: this.savedPage,
        }
    },
    watch: {
        savedPage: function() {
            this.curPage = this.savedPage;
        },
    },
    methods: {
        onCurPageChange(page) {
            const newPage = parseInt(this.curPage, 10);

            if (
                newPage &&
                newPage > 0 &&
                newPage <= this.totalPages &&
                newPage !== this.savedPage
            ) {
                this.onPageChange(newPage);
            } else {
                this.curPage = this.savedPage;
            }
        },
    },
});
</script>
