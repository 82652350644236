var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "driv-corp-list-view-item-container" }, [
    _c("div", { staticClass: "driv-corp-list-view-item-image" }, [
      _c("img", { attrs: { src: _vm.imageSrc, alt: _vm.product.title } })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "driv-corp-list-view-item-header" },
      [
        _c("driv-gpi-part-name-section", {
          attrs: {
            product: _vm.product,
            partName: _vm.product.product,
            togglePartSelection: _vm.togglePartSelection,
            isPartSelected: _vm.isPartSelected,
            isReadOnly: _vm.isReadOnly
          },
          on: {
            goToDetails: function() {
              return _vm.handleGoToDetails(
                _vm.product.part_number,
                _vm.product.brand,
                _vm.detailsPath
              )
            }
          }
        }),
        _vm._v(" "),
        _vm.hasDiagram
          ? _c(
              "button",
              {
                staticClass: "interactive-diagrams-btn",
                on: {
                  click: function($event) {
                    return _vm.openDiagramPage(_vm.product)
                  }
                }
              },
              [_c("fmmp-i18n", { attrs: { text: "Interactive Diagrams" } })],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "driv-corp-list-view-item-details" }, [
      _c(
        "div",
        { staticClass: "driv-corp-list-view-item-info" },
        [
          _c("fmmp-i18n", {
            staticClass: "driv-corp-list-view-item-info-header",
            attrs: { text: "Application criteria" }
          }),
          _vm._v(" "),
          _vm.isApplicationCriteriaDataShown
            ? _vm._l(_vm.appCriteriaItems, function(item) {
                return _c(
                  "div",
                  { key: item.label, staticClass: "details-item" },
                  [
                    _c("fmmp-i18n", {
                      staticClass: "details-item-label",
                      attrs: { text: item.label }
                    }),
                    _vm._v(":\n                    "),
                    _c("span", { staticClass: "details-item-value" }, [
                      _vm._v(_vm._s(item.value))
                    ])
                  ],
                  1
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.vcdbAttributes && _vm.isApplicationCriteriaDataShown
            ? _vm._l(_vm.vcdbAttributes, function(item) {
                return _c(
                  "div",
                  { key: item.property, staticClass: "details-item" },
                  [
                    _c("fmmp-i18n", {
                      staticClass: "details-item-label",
                      attrs: { text: item.property }
                    }),
                    _vm._v(":\n                    "),
                    _c("span", { staticClass: "details-item-value" }, [
                      _vm._v(_vm._s(item.value))
                    ])
                  ],
                  1
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.product.qty
            ? _c(
                "div",
                { staticClass: "details-item" },
                [
                  _c("fmmp-i18n", {
                    staticClass: "details-item-label",
                    attrs: { text: _vm.applicationQuantity.property }
                  }),
                  _vm._v(":\n                "),
                  _c("span", { staticClass: "details-item-value" }, [
                    _vm._v(_vm._s(_vm.applicationQuantity.value))
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.isAdditionalInfoBlockShown
        ? _c(
            "div",
            { staticClass: "driv-corp-list-view-item-additional-info" },
            [
              _c("fmmp-i18n", {
                staticClass: "driv-corp-list-view-item-info-header",
                attrs: { text: _vm.additionalInfoTitle }
              }),
              _vm._v(" "),
              !_vm.performanceEngineSearchType
                ? _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.product.additional_fit_criteria) +
                        "\n            "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.product.notes && _vm.performanceEngineSearchType
                ? _vm._l(_vm.product.notes, function(note) {
                    return _c(
                      "div",
                      { key: note.note_id, staticClass: "details-item" },
                      [_c("span", [_vm._v(_vm._s(note.note))])]
                    )
                  })
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }