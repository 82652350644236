<template>
    <div class="driv-part-number-tab">
        <div v-for="product in products" :key="product.part_name" class="driv-part-number-tab-item">
            <div class="driv-part-number-tab-item-image list-desktop">
                <img :src="getImageSrc(product.dam_assets)" :alt="product.part_name" />
            </div>
            <driv-gpi-part-name-section
                v-if="isGurusProductInsights"
                :product="product"
                @goToDetails="() => getPartDetail(product.part_number, product.brand, detailsPath)"
                :partName="product.part_name"
                :togglePartSelection="togglePartSelection"
                :isPartSelected="isPartSelected"
                :attributeNameValue="checkboxAttrName.products"
                :isReadOnly="isReadOnly"
            />
            <driv-corp-part-name-section
                v-else
                :handleGoToDetails="
                    () => getPartDetail(product.part_number, product.brand, detailsPath)
                "
                :product="product"
                :partName="product.part_name"
            />
            <div v-if="!hideWtbBtns" class="driv-part-number-tab-item-ctas">
                <a
                    @click="
                        () =>
                            handleWtbClick({
                                brand: product.wtb_brand_name,
                                locType: 'install',
                                partNumber: product.part_number,
                            })
                    "
                    class="driv-part-number-tab-item-cta button-secondary"
                >
                    {{ getTranslation("Get it installed") }}
                </a>
                <a
                    @click="
                        () =>
                            handleWtbClick({
                                brand: product.wtb_brand_name,
                                locType: 'store',
                                partNumber: product.part_number,
                            })
                    "
                    class="driv-part-number-tab-item-cta button-secondary"
                >
                    {{ getTranslation("Buy in store") }}
                </a>
                <driv-dropdown-button
                    v-if="product.online_store_list && product.online_store_list.length"
                    buttonClasses="driv-part-number-tab-item-cta button-secondary"
                    :text="getTranslation('Buy now')"
                    :options="
                        mapPartOnlineStoreToOptions(product.online_store_list, product.part_number)
                    "
                ></driv-dropdown-button>
            </div>
        </div>
    </div>
</template>

<script>
import { WTB_EVENTS } from "../../../../common/partFinderCorporate.constants";
import { CHECKBOX_ATTRIBUTE_NAME } from "../constants";
import {
    createUrlToGoToSearchResults,
    mapPartOnlineStoreToOptions,
    getTranslation,
} from "../../../../common/partFinderCorporate.helpers";
import { getCorporateWtbUrl } from "../helpers";

const DEFAULT_IMG_SRC = "/content/dam/placeholders/catalog-part-image.png";

export default Vue.component("driv-part-number-tab", {
    data() {
        return {
            checkboxAttrName: CHECKBOX_ATTRIBUTE_NAME,
        };
    },
    props: {
        wtbPath: {
            type: String,
            default: "",
        },
        products: {
            type: Array,
            default: () => [],
        },
        detailsPath: {
            type: String,
            default: "",
        },
        hideWtbBtns: {
            type: Boolean,
            default: false,
        },
        // only for GPI
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        togglePartSelection: Function,
        isPartSelected: Function,
    },
    computed: {
        isGurusProductInsights() {
            return window.brand === "gpi";
        },
    },
    methods: {
        getImageSrc(dam_assets) {
            return dam_assets?.productPrimaries[0]?.url || DEFAULT_IMG_SRC;
        },
        getPartDetail(partNumber, brandCode, path) {
            if (!path) return;

            Vue.Global.Analytics.trackEvent("Part Search Results Link", "Part", partNumber, null, {
                callback: function () {
                    window.location.assign(
                        createUrlToGoToSearchResults({
                            queryObj: { part_number: partNumber, brand_code: brandCode },
                            path,
                        }),
                    );
                },
            });
        },
        handleWtbClick({ brand, locType, partNumber }) {
            if (this.wtbPath) {
                const url = getCorporateWtbUrl({
                    locType,
                    brand,
                    wtbPath: this.wtbPath,
                });

                Vue.Global.Analytics.trackEvent(
                    "where-to-buy",
                    WTB_EVENTS[locType],
                    partNumber,
                    null,
                    {
                        callback: function () {
                            window.location.assign(url);
                        },
                    },
                );
            }
        },
        mapPartOnlineStoreToOptions,
        getTranslation,
    },
});
</script>
