import {
    BASE_PROPS,
    EMPTY_ITEM,
    FIELDS,
    INITIAL_CONFIG_FOR_URL_CHANGE,
    INITIAL_FIELDS_CONFIG,
    SPEC_PROPS,
} from "./constants";
import {
    getModelFromFieldObject,
    getOptionsConfigByContentSilosValue,
    getOptionsFromResponse,
    resetField,
} from "./helpers";
import { getQsParams } from "../helpers";
import { PART_FINDER_CORPORATE_SEARCH_TYPES } from "../../../../common/partFinderCorporate.constants";
import { createUrlToGoToSearchResults } from "../../../../common/partFinderCorporate.helpers";

const MAKE_VALUE = "MakeID";
const MAKE_LABEL = "MakeName";
const MODEL_VALUE = "ModelID";
const MODEL_LABEL = "ModelName";
const ENGINE_VALUE = "EngineID";
const ENGINE_LABEL = "EngineBase";
const ENGINE_VERSION = "engineVersion";

const specs = {};
const { LOOK_UP_IDS } = Vue.PartFinder;

const resetDependentFields = (field) => {
    const config = getOptionsConfigByContentSilosValue(optionsConfig);
    const fieldNames = Object.keys(config.fields);
    const dependentFieldsStart = fieldNames.indexOf(field);
    const dependentFieldNames = fieldNames.slice(dependentFieldsStart);

    dependentFieldNames.forEach((field) => {
        resetField(fieldsConfig[field]);
    });
};

const resolveNextDependentFields = (currentField) => {
    if (fieldsConfig[currentField]?.model?.value) {
        const fieldValue = fieldsConfig[currentField].model;
        const config = getOptionsConfigByContentSilosValue(optionsConfig);

        config.fields[currentField].onInput(fieldValue, true);
    }
};

const resolveDependentFields = (field) => (value, includeDependentFieldsItems) => {
    if (fieldsConfig[field]?.model) {
        !includeDependentFieldsItems && resetDependentFields(field);
    }
    fieldsConfig[field].disabled = false;

    FIELDS_ACTIONS[field](value, includeDependentFieldsItems);

    handleUrlChange(configForUrlChange);
};

const setModelAndEmitAction = (field, resolveNextDeps) => {
    fieldsConfig[field].model = fieldsConfig[field].items[0];
    const config = getOptionsConfigByContentSilosValue(optionsConfig);

    config.fields[field].onInput(fieldsConfig[field].model, resolveNextDeps);

    handleUrlChange(configForUrlChange);
};

/* Change URL */

const handleUrlChange = ({ options, searchParams }) => {
    const path = window.location.pathname.replace(".html", "");

    if (path) {
        const optionsObj = options.reduce((acc, optKey) => {
            acc[optKey] = true;
            return acc;
        }, {});

        const getSelectedValue = (key) =>
            optionsObj[key] && getModelFromFieldObject(fieldsConfig[key]);

        const year = getSelectedValue(FIELDS.YEAR);
        const make = getSelectedValue(FIELDS.MAKE);
        const model = getSelectedValue(FIELDS.MODEL);
        const engine = getSelectedValue(FIELDS.ENGINE);

        const queryObj = {
            searchType: PART_FINDER_CORPORATE_SEARCH_TYPES.TORQUE,
            ...(searchParams.specificationType
                ? { specificationType: searchParams.specificationType }
                : {}),
            ...(searchParams.contentSilos ? { contentSilos: searchParams.contentSilos } : {}),
            ...(year?.value ? { year } : {}),
            ...(make?.value ? { make } : {}),
            ...(model?.value ? { model } : {}),
            ...(engine?.value ? { engine } : {}),
        };

        const url = createUrlToGoToSearchResults({ queryObj, path });

        window.history.pushState({ urlPath: url }, document.title, url);
    }
};

/* Get data */

const getYears = (years, resolveNextDeps) => {
    fieldsConfig[FIELDS.YEAR].loading = true;

    Promise.resolve(years)
        .then((res) => {
            if (res.length === 1) {
                fieldsConfig[FIELDS.YEAR].items = res[0].map(
                    getOptionsFromResponse([FIELDS.YEAR], [FIELDS.YEAR]),
                );
                fieldsConfig[FIELDS.YEAR].onInput(
                    res[0].map(getOptionsFromResponse([FIELDS.YEAR], [FIELDS.YEAR])),
                );
            } else {
                fieldsConfig[FIELDS.YEAR].items = res.map(
                    getOptionsFromResponse([FIELDS.YEAR], [FIELDS.YEAR]),
                );
            }
            if (resolveNextDeps) {
                resolveNextDependentFields(FIELDS.YEAR);
            }
        })
        .catch(() => {
            fieldsConfig[FIELDS.YEAR].items = [];
        })
        .finally(() => {
            fieldsConfig[FIELDS.YEAR].loading = false;
        });
};

const getTorqueMakes = (year, resolveNextDeps) => {
    fieldsConfig[FIELDS.MAKE].loading = true;

    Vue.CatalogApi.CatalogApiService.getTorqueMakes(year.value)
        .then((res) => {
            fieldsConfig[FIELDS.MAKE].items = res[FIELDS.MAKE].map(
                getOptionsFromResponse(MAKE_VALUE, MAKE_LABEL),
            );
            if (res[FIELDS.MAKE].length === 1) {
                setModelAndEmitAction(FIELDS.MAKE, resolveNextDeps);
            } else if (resolveNextDeps) {
                resolveNextDependentFields(FIELDS.MAKE);
            }
        })
        .catch(() => {
            fieldsConfig[FIELDS.MAKE].items = [];
        })
        .finally(() => {
            fieldsConfig[FIELDS.MAKE].loading = false;
        });
};

const getTorqueModels = (value, resolveNextDeps) => {
    const params = {
        yearId: fieldsConfig[FIELDS.YEAR].model?.value,
        makeId: value?.value,
    };

    fieldsConfig[FIELDS.MODEL].loading = true;

    Vue.CatalogApi.CatalogApiService.getTorqueModels(params)
        .then((res) => {
            fieldsConfig[FIELDS.MODEL].items = res[FIELDS.MODEL].map(
                getOptionsFromResponse(MODEL_VALUE, MODEL_LABEL),
            );
            if (res[FIELDS.MODEL].length === 1) {
                setModelAndEmitAction(FIELDS.MODEL, resolveNextDeps);
            } else if (resolveNextDeps) {
                resolveNextDependentFields(FIELDS.MODEL);
            }
        })
        .catch(() => {
            fieldsConfig[FIELDS.MODEL].items = [];
        })
        .finally(() => {
            fieldsConfig[FIELDS.MODEL].loading = false;
        });
};

const getTorqueEngine = (value, resolveNextDeps) => {
    const params = {
        yearId: fieldsConfig[FIELDS.YEAR].model?.value,
        makeId: fieldsConfig[FIELDS.MAKE].model?.value,
        modelId: value?.value,
    };

    fieldsConfig[FIELDS.ENGINE].loading = true;

    Vue.CatalogApi.CatalogApiService.getTorqueEngines(params)
        .then((res) => {
            if (!res[FIELDS.ENGINE]?.length) {
                fieldsConfig[FIELDS.ENGINE].items = [EMPTY_ITEM];
                setModelAndEmitAction(FIELDS.ENGINE, resolveNextDeps);
            } else {
                fieldsConfig[FIELDS.ENGINE].items = res[FIELDS.ENGINE].map(
                    getOptionsFromResponse(ENGINE_VALUE, ENGINE_LABEL, ENGINE_VERSION),
                );
                if (res[FIELDS.ENGINE].length === 1) {
                    setModelAndEmitAction(FIELDS.ENGINE, resolveNextDeps);
                } else if (resolveNextDeps) {
                    resolveNextDependentFields(FIELDS.ENGINE);
                }
            }
        })
        .catch(() => {
            fieldsConfig[FIELDS.ENGINE].items = [];
        })
        .finally(() => {
            fieldsConfig[FIELDS.ENGINE].loading = false;
        });
};

const handleBaseOrSpecRequestError = (engineField) => {
    engineField.error = true;

    if (engineField.onError) {
        engineField.onError(engineField.error);
    }
};

const getTorqueBase = (value) => {
    const yearId = fieldsConfig[FIELDS.YEAR].model?.value;
    const makeId = fieldsConfig[FIELDS.MAKE].model?.value;
    const modelId = fieldsConfig[FIELDS.MODEL].model?.value;
    const engineId = value?.value;
    const { contentSilos } = getQsParams();
    const engineField = fieldsConfig[FIELDS.ENGINE];

    engineField.error = false;

    handleUrlChange(configForUrlChange);

    Vue.CatalogApi.CatalogApiService.getTorqueBase({ yearId, makeId, modelId })
        .then((res) => {
            const baseId = res[BASE_PROPS.BASE][BASE_PROPS.BASE_ID];

            Vue.CatalogApi.CatalogApiService.getTorqueSpecification({
                baseId,
                contentSilos: contentSilos.value,
                engineId,
            })
                .then((res) => {
                    specs.data = res[SPEC_PROPS.SPEC][SPEC_PROPS.APPS];
                    specs.baseId = baseId;
                    specs.onNewSpecs && specs.onNewSpecs();
                })
                .catch(() => {
                    handleBaseOrSpecRequestError(engineField);
                });
        })
        .catch(() => {
            handleBaseOrSpecRequestError(engineField);
        });
};

const FIELDS_ACTIONS = {
    [FIELDS.YEAR]: getYears,
    [FIELDS.MAKE]: getTorqueMakes,
    [FIELDS.MODEL]: getTorqueModels,
    [FIELDS.ENGINE]: getTorqueEngine,
};

/* Configs */

const fieldsConfig = INITIAL_FIELDS_CONFIG;
const configForUrlChange = INITIAL_CONFIG_FOR_URL_CHANGE;

const optionsConfig = {
    [LOOK_UP_IDS.TORQUE_CYLINDER_HEAD_CONTENT]: {
        onInput: resolveDependentFields(FIELDS.YEAR),
        fields: {
            [FIELDS.YEAR]: {
                onInput: resolveDependentFields(FIELDS.MAKE),
            },
            [FIELDS.MAKE]: {
                onInput: resolveDependentFields(FIELDS.MODEL),
            },
            [FIELDS.MODEL]: {
                onInput: resolveDependentFields(FIELDS.ENGINE),
            },
            [FIELDS.ENGINE]: {
                onInput: getTorqueBase,
            },
        },
    },
    [LOOK_UP_IDS.TORQUE_HUB_WHEEL_BEARING_CONTENT]: {
        onInput: resolveDependentFields(FIELDS.YEAR),
        fields: {
            [FIELDS.YEAR]: {
                onInput: resolveDependentFields(FIELDS.MAKE),
            },
            [FIELDS.MAKE]: {
                onInput: resolveDependentFields(FIELDS.MODEL),
            },
            [FIELDS.MODEL]: {
                onInput: getTorqueBase,
            },
        },
    },
};

export default {
    fieldsConfig,
    optionsConfig,
    specs,
    configForUrlChange,
};
