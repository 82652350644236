var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "p",
    { staticClass: "selected-parts-amount" },
    [
      _c("fmmp-i18n", {
        attrs: { text: "Max 10 items can be selected at a time." }
      }),
      _vm._v("\n    " + _vm._s(_vm.getSelectedPartsQuantity()) + "\n")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }