<template>
    <div :key="componentKey">
        <fmmp-i18n
            v-if="isErrorMessageShown"
            text="no torque specification data found"
            class="error-message"
        />
        <div v-else-if="!loading">
            <div :class="['driv-torque-table', { shortened: !isCylinderHead }]">
                <div class="torque-one-result-table" v-if="specifications.length === 1">
                    <a
                        @click="
                            goToTorqueDetails({
                                baseId,
                                documentId: documentIdForOneResultTable,
                                appId: specifications[0].ApplicationID,
                                 qualifierId: getQualifierId(specifications[0]),
                            })
                        "
                    >
                        <i class="fas fa-wrench desktop"></i>
                        <fmmp-i18n :text="instructionDesktop" class="table-instruction desktop" />
                        <fmmp-i18n :text="instructionMobile" class="table-instruction mobile" />
                    </a>
                </div>
                <div class="torque-all-results-table" v-else>
                    <fmmp-i18n :text="instructionDesktop" class="table-instruction desktop" />
                    <div class="torque-results-table">
                        <table>
                            <thead>
                                <tr>
                                    <template v-for="column in columnNames">
                                        <th :key="column">{{ getTranslation(column) }}</th>
                                    </template>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(row, i) in specifications">
                                    <tr :key="i">
                                        <template v-for="document in getDocs(row)">
                                            <td :key="document.DocumentID">
                                                <a
                                                    @click="
                                                        goToTorqueDetails({
                                                            baseId,
                                                            documentId: document.DocumentID,
                                                            appId: row.ApplicationID,
                                                            qualifierId: getQualifierId(row),
                                                        })
                                                    "
                                                >
                                                    <i class="fas fa-wrench desktop"></i>
                                                    <fmmp-i18n
                                                        :text="instructionMobile"
                                                        class="mobile"
                                                    />
                                                </a>
                                            </td>
                                        </template>
                                        <td v-if="row.SubModel">
                                            <span class="label mobile">
                                                <fmmp-i18n text="Submodel" />{{ ":" }}
                                            </span>
                                            <span class="value">
                                                {{
                                                    transformToMissedValue(
                                                        row.SubModel && row.SubModel.SubModelName,
                                                    )
                                                }}
                                            </span>
                                        </td>
                                        <td
                                            v-if="
                                                checkForNotesOrProcsPresence(row) && isCylinderHead
                                            "
                                        >
                                            <span class="label mobile">
                                                <fmmp-i18n text="Specifications" />{{ ":" }}
                                            </span>
                                            <span class="value">
                                                {{
                                                    transformToMissedValue(
                                                        concatenateSpecString(row),
                                                    )
                                                }}
                                            </span>
                                        </td>
                                        <td v-if="row.Engine">
                                            <span class="label mobile">
                                                <fmmp-i18n text="Engine Version" />{{ ":" }}
                                            </span>
                                            <span class="value">
                                                {{
                                                    transformToMissedValue(
                                                        row.Engine && row.Engine.engineVersion,
                                                    )
                                                }}
                                            </span>
                                        </td>
                                        <td v-if="row.Qualifiers">
                                            <span class="label mobile">
                                                <fmmp-i18n text="Qualifiers" />{{ ":" }}
                                            </span>
                                            <span class="value">
                                                {{
                                                    transformToMissedValue(
                                                        row.Qualifiers.length &&
                                                            row.Qualifiers[0].Description,
                                                    )
                                                }}
                                            </span>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import configs from "./TorqueYmmSearchConfig";
import { getQsParams } from "../helpers";
import {
    getTranslation,
    transformToMissedValue,
} from "../../../../common/partFinderCorporate.helpers";
import { goToTorqueDetails } from "./helpers";
import { FIELDS } from "./constants";

const { specs, fieldsConfig } = configs;
const { LOOK_UP_IDS } = Vue.PartFinder;

const CYLINDER_COLUMNS = ["torque", "submodel", "specifications", "engine version"];
const HUB_WHEEL_COLUMNS = ["torque", "Qualifiers"];

const DEFAULT_DOCS = [{ DocumentID: "", Format: "" }];

export default Vue.component("driv-corp-torque-results-table", {
    data() {
        return {
            instructionDesktop: "Click the wrench to view the torque specifications",
            instructionMobile: "Click to view the torque specifications",
            specifications: [],
            componentKey: 0,
            baseId: null,
            isError: false,
            loading: true,
        };
    },
    methods: {
        transformToMissedValue,
        goToTorqueDetails,
        getTranslation,
        forceRerender() {
            this.componentKey += 1;
        },
        concatenateSpecString(obj) {
            const prefix = getTranslation("Torque Spec");
            const notesString = obj.Notes[0]?.Text
                ? `; ${getTranslation("NOTE")}: ${obj.Notes[0]?.Text}`
                : "";

            const procNotesString = obj.ServiceProcedures[0].ProcNote
                ? obj.ServiceProcedures[0].ProcNote?.map(({ Proc, Torque, Units, Degrees }) => {
                      return `; ${Proc || ""}: ${Torque || ""} ${Units || ""} ${Degrees || ""}`;
                  }).join(" ")
                : "";

            const anyNoteExists = procNotesString || notesString;
            const concatenatedString = `${prefix}${procNotesString || ""}${notesString}`;

            return anyNoteExists && concatenatedString;
        },
        checkForNotesOrProcsPresence(obj) {
            return obj.ServiceProcedures?.[0]?.ProcNote || obj.Notes;
        },
        getDocs(tableRow) {
            const documents = tableRow?.ServiceProcedures?.[0]?.ReferenceSet?.Documents;
            return documents?.length ? documents : DEFAULT_DOCS;
        },
        getQualifierId(tableRow) {
            if (!tableRow.Qualifiers || !tableRow.Qualifiers.length) return 0;
                return tableRow.Qualifiers[0].QualifierID;
        },
    },
    computed: {
        isCylinderHead() {
            const { contentSilos } = getQsParams();
            return contentSilos.value === LOOK_UP_IDS.TORQUE_CYLINDER_HEAD_CONTENT;
        },
        columnNames() {
            return this.isCylinderHead ? CYLINDER_COLUMNS : HUB_WHEEL_COLUMNS;
        },
        isErrorMessageShown() {
            return !this.loading && (this.isError || !this.specifications.length);
        },
        documentIdForOneResultTable() {
            this.specifications[0].ServiceProcedures?.[0]?.ReferenceSet?.Documents?.[0]?.DocumentID;
        },
    },
    created() {
        const engineField = fieldsConfig[FIELDS.ENGINE];

        specs.onNewSpecs = () => {
            this.specifications = specs?.data;
            this.baseId = specs?.baseId;
            this.forceRerender();
            this.loading = false;
        };

        engineField.onError = () => {
            this.isError = engineField.error;
            this.loading = false;
        };
    },
});
</script>
