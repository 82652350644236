var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { staticClass: "product__info" }, [
    _c("td", { staticClass: "product-image" }, [
      _c("img", { attrs: { src: _vm.imageSrc, alt: _vm.product.part_name } })
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "product-name-container" }, [
      _c("input", {
        staticClass: "select-part",
        attrs: { type: "checkbox", id: _vm.customId, disabled: _vm.isReadOnly },
        domProps: { checked: _vm.isPartSelected(_vm.customId) },
        on: {
          click: function($event) {
            return _vm.togglePartSelection($event)
          }
        }
      }),
      _vm._v(" "),
      _c("i", {
        class: [
          "fa-solid fa-chevron-right product-quick-details",
          { active: _vm.isDetailsOpen }
        ],
        on: { click: _vm.toggleQuickDetails }
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "product-name",
          attrs: { rel: "noopener noreferrer", target: "_blank" },
          on: {
            click: function($event) {
              return _vm.handleGoToDetails(
                _vm.product.part_number,
                _vm.product.brand,
                _vm.detailsPath
              )
            }
          }
        },
        [
          _vm._v(
            "\n            " + _vm._s(_vm.product.part_number) + "\n        "
          )
        ]
      ),
      _vm._v(" "),
      _vm.hasDiagram
        ? _c(
            "button",
            {
              staticClass: "interactive-diagrams-btn",
              on: {
                click: function($event) {
                  return _vm.openDiagramPage(_vm.product)
                }
              }
            },
            [_c("fmmp-i18n", { attrs: { text: "Interactive Diagrams" } })],
            1
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.checkIfArrayHasColumn(_vm.columnNames.SUB_MODEL)
      ? _c("td", { staticClass: "product-submodel" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.transformToMissedValue(
                  typeof _vm.product.sub_model === "object" &&
                    _vm.product.sub_model.value
                )
              ) +
              "\n    "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.checkIfArrayHasColumn(_vm.columnNames.DRIVE_WHEEL)
      ? _c("td", { staticClass: "product-drive-wheel" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.transformToMissedValue(
                  typeof _vm.product.drive === "object" &&
                    _vm.product.drive.value
                )
              ) +
              "\n    "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.checkIfArrayHasColumn(_vm.columnNames.ENGINE_BASE)
      ? _c("td", { staticClass: "product-engine-base" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.transformToMissedValue(_vm.product.engine_base_value)
              ) +
              "\n    "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.checkIfArrayHasColumn(_vm.columnNames.ENGINE_VIN)
      ? _c("td", { staticClass: "product-engine-vin" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.transformToMissedValue(
                  _vm.product.engine_vin && _vm.product.engine_vin.value
                )
              ) +
              "\n    "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.checkIfArrayHasColumn(_vm.columnNames.POSITION)
      ? _c("td", { staticClass: "product-position" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.transformToMissedValue(
                  _vm.product.position && _vm.product.position.value
                )
              ) +
              "\n    "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.checkIfArrayHasColumn(_vm.columnNames.QUALIFIERS)
      ? _c(
          "td",
          {
            class: [
              "product-qualifiers",
              { "align-left": _vm.isQualifierAlignedLeft }
            ]
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.transformToMissedValue(
                    _vm.product.additional_fit_criteria
                  )
                ) +
                "\n    "
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.checkIfArrayHasColumn(_vm.columnNames.QUANTITY)
      ? _c("td", { staticClass: "product-vehicle-quantity" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.transformToMissedValue(_vm.product.qty)) +
              "\n    "
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }