var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "driv-corp-list-pagination" },
    [
      _c("fmmp-i18n", { attrs: { text: "page" } }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.trim",
            value: _vm.curPage,
            expression: "curPage",
            modifiers: { trim: true }
          }
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.curPage },
        on: {
          change: _vm.onCurPageChange,
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.curPage = $event.target.value.trim()
          },
          blur: function($event) {
            return _vm.$forceUpdate()
          }
        }
      }),
      _vm._v(" "),
      _c("fmmp-i18n", { attrs: { text: "of" } }),
      _vm._v("\n    " + _vm._s(_vm.totalPages) + "\n    "),
      _c(
        "button",
        {
          attrs: { disabled: _vm.curPage === 1 },
          on: {
            click: function() {
              return _vm.onPageChange(_vm.curPage - 1)
            }
          }
        },
        [_c("i", { staticClass: "fa fa-angle-left" })]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          attrs: { disabled: _vm.curPage === _vm.totalPages },
          on: {
            click: function() {
              return _vm.onPageChange(_vm.curPage + 1)
            }
          }
        },
        [_c("i", { staticClass: "fa fa-angle-right" })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }