var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "specification-filter" }, [
    _c(
      "ul",
      _vm._l(_vm.optionsList, function(option) {
        return _c(
          "li",
          {
            key: option,
            class: [
              "specification-filter__option",
              { selected: option === _vm.selectedOption }
            ],
            on: {
              click: function($event) {
                _vm.selectedOption = option
              }
            }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.getTranslation(option)) +
                "\n        "
            )
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }