var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "part-number-results-wrapper" },
    [
      _vm.showErrorScreen
        ? _c("driv-empty-part-results-page", {
            attrs: { noPartsFound: _vm.noPartsFound }
          })
        : _c(
            "div",
            { staticClass: "part-number-results-content" },
            [
              _vm.isGurusProductInsights
                ? _c(
                    "div",
                    { staticClass: "part-number-results-selection-info" },
                    [
                      _c("driv-gpi-selected-parts-amount", {
                        attrs: { partsAmount: _vm.partsAmount }
                      }),
                      _vm._v(" "),
                      _c("driv-gpi-update-case-btn", {
                        attrs: { isReadOnly: _vm.isReadOnlyVersionGpi },
                        on: { onClick: _vm.sendPartsData }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "driv-tabs",
                [
                  _vm.partsList.products.length
                    ? _c(
                        "driv-tab",
                        { attrs: { name: _vm.partsTabName, selected: true } },
                        [
                          _c("driv-part-number-tab", {
                            attrs: {
                              wtbPath: _vm.wtbPath,
                              products: _vm.partsList.products,
                              detailsPath: _vm.detailsPath,
                              hideWtbBtns: _vm.hideWtbBtns,
                              isPartSelected: _vm.isPartSelected,
                              togglePartSelection: _vm.togglePartSelection,
                              isReadOnly: _vm.isReadOnlyVersionGpi
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.partsList.interchange_products.length
                    ? _c(
                        "driv-tab",
                        { attrs: { name: _vm.interchangesTabName } },
                        [
                          _c("driv-interchanges-tab", {
                            attrs: {
                              products: _vm.partsList.interchange_products,
                              detailsPath: _vm.detailsPath,
                              isPartSelected: _vm.isPartSelected,
                              togglePartSelection: _vm.togglePartSelection,
                              isReadOnly: _vm.isReadOnlyVersionGpi
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "driv-notification-modal",
        {
          attrs: {
            notificationModal: _vm.notificationModal,
            modalTitle: "Part Details"
          },
          on: { updateModalState: _vm.updateNotificationModalState }
        },
        [
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c("fmmp-i18n", {
                staticClass: "modal-text",
                attrs: { text: "Max 10 items can be selected at a time." }
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("fmmp-i18n", {
                staticClass: "modal-text bold",
                attrs: { text: "10 out of 10 are selected" }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }