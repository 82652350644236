<template>
    <div class="driv-corp-item-part-name">
        <div class="driv-corp-item-part-name-subheading">
            <div class="driv-corp-item-part-name-part-number-wrapper">
                <fmmp-i18n text="Part No" />:
                <a
                    class="driv-corp-item-part-name-part-number"
                    @click="handleGoToDetails"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {{ product.part_number }}
                </a>
            </div>
            <div class="driv-corp-item-part-name-brand">
                <span>{{ product.brand_name }}</span>
                <span v-if="product.sub_brand_name" class="subbrand">
                    - {{ product.sub_brand_name }}
                </span>
            </div>
        </div>
        <div class="driv-corp-item-part-name-title">
            <a @click="handleGoToDetails" rel="noopener noreferrer" target="_blank">
                {{ partName }}
            </a>
        </div>
    </div>
</template>

<script>
export default Vue.component("driv-corp-part-name-section", {
    props: {
        handleGoToDetails: Function,
        product: Object,
        partName: {
            type: String,
            default: "",
        },
    },
});
</script>
