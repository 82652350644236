<template>
    <div class="driv-interchanges-tab-wrapper">
        <table class="driv-interchanges-tab">
            <thead class="driv-interchanges-tab-head">
                <tr class="driv-interchanges-tab-head-row">
                    <th v-if="isGurusProductInsights" class="driv-interchanges-tab-head-cell"></th>
                    <th class="driv-interchanges-tab-head-cell">
                        <fmmp-i18n :text="columns.manufacturer" />
                    </th>
                    <th class="driv-interchanges-tab-head-cell">
                        <fmmp-i18n :text="columns.partNumber" />
                    </th>
                    <th class="driv-interchanges-tab-head-cell">
                        <fmmp-i18n :text="columns.ownPartNumber" />
                    </th>
                    <th class="driv-interchanges-tab-head-cell">
                        <fmmp-i18n :text="columns.productType" />
                    </th>
                    <th v-if="isGurusProductInsights" class="driv-interchanges-tab-head-cell">
                        <fmmp-i18n :text="columns.partNotes" />
                    </th>
                </tr>
            </thead>
            <tbody class="driv-interchanges-tab-body">
                <tr
                    v-for="(product, index) in products"
                    :key="index"
                    class="driv-interchanges-tab-row"
                >
                    <td v-if="isGurusProductInsights" class="driv-interchanges-tab-cell">
                        <input
                            type="checkbox"
                            class="select-part"
                            :name="checkboxAttrName.interchange_products"
                            :id="generateCustomId(product)"
                            :checked="isPartSelected(generateCustomId(product))"
                            :disabled="isReadOnly"
                            @click="togglePartSelection($event)"
                        />
                    </td>
                    <td class="driv-interchanges-tab-cell">
                        <fmmp-i18n
                            :text="columns.manufacturer"
                            class="driv-interchanges-tab-cell-heading"
                        />
                        {{ product.brand_name }}
                    </td>
                    <td class="driv-interchanges-tab-cell">
                        <fmmp-i18n
                            :text="columns.partNumber"
                            class="driv-interchanges-tab-cell-heading"
                        />
                        {{ product.part_number }}
                    </td>
                    <td class="driv-interchanges-tab-cell">
                        <fmmp-i18n
                            :text="columns.ownPartNumber"
                            class="driv-interchanges-tab-cell-heading"
                        />
                        <a
                            class="driv-interchanges-tab-part-number"
                            @click="
                                getPartDetail(
                                    product.own_part_number,
                                    product.own_brand_code,
                                    detailsPath,
                                )
                            "
                            rel="noopener noreferrer"
                            target="_blank"
                            >{{ product.own_part_number }}</a
                        >
                    </td>
                    <td class="driv-interchanges-tab-cell">
                        <fmmp-i18n
                            :text="columns.productType"
                            class="driv-interchanges-tab-cell-heading"
                        />
                        {{ product.part_name }}
                    </td>
                    <td
                        v-if="isGurusProductInsights"
                        :class="[
                            'driv-interchanges-tab-cell part-notes',
                            getPartNotesClass(product),
                        ]"
                    >
                        <fmmp-i18n
                            :text="columns.partNotes"
                            class="driv-interchanges-tab-cell-heading"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { createUrlToGoToSearchResults } from "../../../../common/partFinderCorporate.helpers";
import { generateCustomId } from "../helpers";
import { CHECKBOX_ATTRIBUTE_NAME } from "../constants";

export default Vue.component("driv-interchanges-tab", {
    data() {
        return {
            checkboxAttrName: CHECKBOX_ATTRIBUTE_NAME,
        };
    },
    props: {
        products: {
            type: Array,
            default: () => [],
        },
        detailsPath: {
            type: String,
            default: "",
        },
        // only for GPI
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        isPartSelected: Function,
        togglePartSelection: Function,
    },
    computed: {
        isGurusProductInsights() {
            return window.brand === "gpi";
        },
        columns() {
            const partNotesColumn = this.isGurusProductInsights ? { partNotes: "Part Notes" } : {};

            return {
                manufacturer: "Manufacturer",
                partNumber: "Part Number",
                ownPartNumber: "Our Part Number",
                productType: "Product Type",
                ...partNotesColumn,
            };
        },
        hasPartNotes() {
            return this.product?.gpi_notes?.note_count;
        },
    },
    methods: {
        getPartDetail(partNumber, brandCode, path) {
            if (!path) return;

            Vue.Global.Analytics.trackEvent("Part Search Results Link", "Part", partNumber, null, {
                callback: function () {
                    window.location.assign(
                        createUrlToGoToSearchResults({
                            queryObj: { part_number: partNumber, brand_code: brandCode },
                            path,
                        }),
                    );
                },
            });
        },
        getPartNotesClass(product) {
            return product?.gpi_notes?.note_count ? "has-part-notes" : "";
        },
        generateCustomId,
    },
});
</script>
