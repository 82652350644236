import { getQsParams } from "../helpers";
import { createUrlToGoToSearchResults } from "../../../../common/partFinderCorporate.helpers";

export const getInitialFieldConfig = (placeholder) => ({
    items: [],
    model: "",
    placeholder,
    disabled: true,
    loading: false,
});

export const getOptionsFromResponse = (valuePropName, labelPropName, engineVersion) => (item) => ({
    value: item[valuePropName],
    label: `${item[labelPropName]} ${engineVersion ? `/ ${item[engineVersion]}` : ""}`,
});

export const resetField = (value) => {
    value.items = [];
    value.model = "";
    value.disabled = true;
};

export const getModelFromFieldObject = (field) => {
    return field?.model || {};
};

export const getOptionsConfigByContentSilosValue = (optionsConfig) => {
    const { contentSilos } = getQsParams();
    return optionsConfig[contentSilos.value];
};

export const goToTorqueDetails = ({ baseId, documentId, appId, qualifierId }) => {
    const queryObj = getQsParams();
    const path = window.location.pathname.replace(".html", "");

    baseId && (queryObj.baseId = baseId);
    documentId && (queryObj.documentId = documentId);
    queryObj.specAppId = appId;
    queryObj.qualifierId = qualifierId;

    const { year, make, model, engine, specAppId } = queryObj;
    const eventParams = [year.label, make.label, model.label];

    engine && eventParams.push(engine.label);

    eventParams.push(`specification app ID: ${specAppId}`);

    baseId && eventParams.push(`base ID: ${baseId}`);
    queryObj.documentId && eventParams.push(`document ID: ${queryObj.documentId}`);

    const url = createUrlToGoToSearchResults({ queryObj, path });

    Vue.Global.Analytics.trackEvent(
        "Part Search Results Link",
        "Part",
        eventParams.join("|"),
        null,
        {
            callback: function () {
                window.location.assign(url);
            },
        },
    );
};
