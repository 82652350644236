<template>
    <li class="driv-corp-list-view-item-container">
        <div class="driv-corp-list-view-item-image list-desktop">
            <img :src="imageSrc" :alt="product.title" />
        </div>
        <div class="driv-corp-list-view-item-content">
            <div class="driv-corp-list-view-item-header">
                <driv-corp-part-name-section
                    :product="product"
                    :partName="product.product"
                    :handleGoToDetails="
                        () => handleGoToDetails(product.part_number, product.brand, detailsPath)
                    "
                />
                <button
                    v-if="hasDiagram"
                    class="interactive-diagrams-btn"
                    @click="openDiagramPage(product)"
                >
                    <fmmp-i18n text="Interactive Diagrams" />
                </button>
                <div class="driv-corp-list-view-item-image">
                    <img :src="imageSrc" :alt="product.title" />
                </div>
                <div class="action-buttons">
                    <a
                        v-if="!hideWtbBtns"
                        class="button-main driv-corp-list-view-item-wtb-button"
                        @click="
                            () =>
                                handleWtbClick({
                                    partNumber: product.part_number,
                                    brand: product.wtb_brand_name,
                                })
                        "
                        >{{ getTranslation("where to buy") }}</a
                    >
                    <driv-dropdown-button
                        v-if="
                            !hideWtbBtns &&
                            product.online_store_list &&
                            product.online_store_list.length
                        "
                        buttonClasses="driv-corp-list-view-item-wtb-button"
                        :text="getTranslation('Buy now')"
                        :options="
                            mapPartOnlineStoreToOptions(
                                product.online_store_list,
                                product.part_number,
                            )
                        "
                    ></driv-dropdown-button>
                </div>
            </div>

            <div class="driv-corp-list-view-item-details">
                <div class="driv-corp-list-view-item-info">
                    <h4 class="driv-corp-list-view-item-info-header">
                        {{ getTranslation("Application criteria") }}
                    </h4>
                    <template v-if="isApplicationCriteriaDataShown">
                        <div
                            class="details-item"
                            v-for="item in appCriteriaItems"
                            :key="item.label"
                        >
                            <span class="details-item-label">
                                {{ getTranslation(item.label) }}:
                            </span>
                            <span class="details-item-value">{{ item.value }}</span>
                        </div>
                    </template>
                    <template v-if="vcdbAttributes && isApplicationCriteriaDataShown">
                        <div
                            class="details-item"
                            v-for="item in vcdbAttributes"
                            :key="item.property"
                        >
                            <span class="details-item-label">
                                {{ getTranslation(item.property) }}:
                            </span>
                            <span class="details-item-value">{{ item.value }}</span>
                        </div>
                    </template>
                    <div v-if="product.qty" class="details-item">
                        <span class="details-item-label">
                            {{ getTranslation(applicationQuantity.property) }}:
                        </span>
                        <span class="details-item-value">{{ applicationQuantity.value }}</span>
                    </div>
                </div>
                <div
                    class="driv-corp-list-view-item-additional-info"
                    v-if="isAdditionalInfoBlockShown"
                >
                    <h4 class="driv-corp-list-view-item-info-header">{{ additionalInfoTitle }}:</h4>
                    <span v-if="!performanceEngineSearchType">
                        {{ product.additional_fit_criteria }}
                    </span>
                    <template v-if="product.notes && performanceEngineSearchType">
                        <div class="details-item" v-for="note in product.notes" :key="note.note_id">
                            <span>{{ note.note }}</span>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
import {
    PART_FINDER_CORPORATE_SEARCH_TYPES,
    WTB_EVENTS,
} from "../../../../common/partFinderCorporate.constants";
import {
    createUrlToGoToSearchResults,
    mapPartOnlineStoreToOptions,
    getTranslation,
    transformToMissedValue,
} from "../../../../common/partFinderCorporate.helpers";
import { getCorporateWtbUrl, getQsParams, openDiagramPage } from "../helpers";

const DEFAULT_IMG_SRC = "/content/dam/placeholders/catalog-part-image.png";

const APPLICATION_CRITERIA_ITEMS = [
    { label: "Position", property: "position" },
    { label: "Drive", property: "drive" },
    { label: "Engine base", property: "engine_base_value" },
    { label: "Designation", property: "engine_designation" },
    { label: "VIN", property: "engine_vin" },
    { label: "Sub-model", property: "sub_model" },
];

export default Vue.component("driv-corp-list-view-item", {
    props: {
        product: Object,
        wtbPath: {
            type: String,
            default: "",
        },
        detailsPath: {
            type: String,
            default: "",
        },
        hideWtbBtns: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        performanceEngineSearchType() {
            const { searchType } = getQsParams();
            return searchType === PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE;
        },
        isApplicationCriteriaDataShown() {
            const { searchType } = getQsParams();
            return ![
                PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE,
                PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_HEAVY_DUTY,
            ].includes(searchType);
        },
        isAdditionalInfoBlockShown() {
            if (this.performanceEngineSearchType) return this.product.notes;
            return (
                this.product.additional_fit_criteria &&
                this.product.additional_fit_criteria !== "N/A"
            );
        },
        additionalInfoTitle() {
            if (this.performanceEngineSearchType) {
                return getTranslation("Application / Part Comments");
            } else if (this.engineSearchType) {
                return getTranslation("Additional application criteria");
            }
            return getTranslation("Qualifiers");
        },
        applicationQuantity() {
            return {
                property: "Application quantity",
                value: this.product.qty,
            };
        },
        vcdbAttributes() {
            const attributes = this.product.vcdb_attributes;
            let mappedVcdbAttrs = [];

            if (attributes) {
                for (const prop in attributes) {
                    if (attributes.hasOwnProperty(prop)) {
                        mappedVcdbAttrs = [
                            ...mappedVcdbAttrs,
                            {
                                property: prop,
                                value: attributes[prop],
                            },
                        ];
                    }
                }
            }

            return mappedVcdbAttrs;
        },
        imageSrc() {
            return (this.product.dam_assets?.productPrimaries[0] || {}).url || DEFAULT_IMG_SRC;
        },
        appCriteriaItems() {
            return APPLICATION_CRITERIA_ITEMS.map(({ label, property }) => {
                if (!this.product[property]) return;

                return {
                    label,
                    value: transformToMissedValue(
                        this.getValue(property, this.product[property]),
                        "-",
                    ),
                };
            }).filter(Boolean);
        },
        hasDiagram() {
            return !!this.product.digital_assets;
        },
    },
    methods: {
        getValue(name, data) {
            switch (true) {
                case typeof data === "string" || typeof data === "number":
                    return data;
                case [
                    "position",
                    "sub_model",
                    "drive",
                    "engine_designation",
                    "engine_vin",
                ].includes(name):
                    return data.value;
                case name === "engine_base_value":
                    return data;
                default:
                    return "-";
            }
        },
        handleWtbClick({ brand, partNumber }) {
            if (this.wtbPath) {
                const url = getCorporateWtbUrl({
                    brand,
                    wtbPath: this.wtbPath,
                });

                Vue.Global.Analytics.trackEvent(
                    "where-to-buy",
                    WTB_EVENTS.store,
                    partNumber,
                    null,
                    {
                        callback: function () {
                            window.location.assign(url);
                        },
                    },
                );
            }
        },
        handleGoToDetails(partNumber, brandCode, path) {
            if (!path && window.brand !== 'commercial-parts') return;

            Vue.Global.Analytics.trackEvent("Part Search Results Link", "Part", partNumber, null, {
                callback: function () {
                    if (window.brand === 'commercial-parts') {
                        window.location.assign(
                            `https://www.drivparts.com/part-details.html?part_number=${partNumber}&brand_code=${brandCode}`
                        );
                    } else {
                        window.location.assign(
                            createUrlToGoToSearchResults({
                                queryObj: { part_number: partNumber, brand_code: brandCode },
                                path,
                            })
                        );
                    }
                },
            });
        },
        mapPartOnlineStoreToOptions,
        getTranslation,
        openDiagramPage,
    },
});
</script>
