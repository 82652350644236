var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ujoint-results" },
    [
      _vm.isErrorShown
        ? _c("driv-empty-part-results-page")
        : [
            _c(
              "ul",
              { staticClass: "ujoint-stepper" },
              _vm._l(_vm.steps, function(step) {
                return _c(
                  "li",
                  {
                    key: step.value,
                    on: {
                      click: function() {
                        return _vm.handleStepperClick(step.value)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(step.label) +
                        "\n            "
                    )
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.isLoading
              ? _c("div", { staticClass: "three-quarters-loader" })
              : _c(_vm.displayedPageComponent, {
                  tag: "component",
                  attrs: {
                    uJointTypes: _vm.uJointTypes,
                    specifications: _vm.uJointSpecifications,
                    detailsPath: _vm.detailsPath
                  }
                })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }