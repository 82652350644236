var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "default-filter-container" }, [
      _c("div", [
        _c("h5", { staticClass: "title" }, [
          _vm._v(
            "\n                " + _vm._s(_vm.colHeaderName) + "\n            "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "select",
        {
          staticClass: "default-filter",
          on: {
            change: function($event) {
              return _vm.handleDefaultFilterChange($event)
            }
          }
        },
        _vm._l(_vm.optionsList, function(option) {
          return _c(
            "option",
            {
              key: option,
              class: [
                "specification-filter__option",
                { selected: option === _vm.selectedOption }
              ],
              domProps: {
                value: option,
                selected: option === _vm.selectedOption
              }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.getTranslation(option)) +
                  "\n            "
              )
            ]
          )
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c("div", [
      _c("h4", { staticClass: "filter-separator" }, [
        _vm._v(_vm._s(_vm.getTranslation("or")))
      ])
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "range-filter-form",
        on: {
          submit: function($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.applyFilter.apply(null, arguments)
          }
        }
      },
      [
        _c("div", { staticClass: "range-filter-form__fields" }, [
          _c("h5", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.getTranslation("Refine Range")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "values-interval" }, [
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.rangeFilter.value_from,
                    expression: "rangeFilter.value_from"
                  }
                ],
                attrs: {
                  type: "number",
                  step: "0.001",
                  placeholder: _vm.inputPlaceholders.start
                },
                domProps: { value: _vm.rangeFilter.value_from },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.rangeFilter,
                        "value_from",
                        $event.target.value
                      )
                    },
                    _vm.updateRangeFilter
                  ]
                }
              })
            ]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.rangeFilter.value_to,
                    expression: "rangeFilter.value_to"
                  }
                ],
                attrs: {
                  type: "number",
                  step: "0.001",
                  placeholder: _vm.inputPlaceholders.end
                },
                domProps: { value: _vm.rangeFilter.value_to },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.rangeFilter, "value_to", $event.target.value)
                    },
                    _vm.updateRangeFilter
                  ]
                }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _vm.errorMessage
          ? _c("fmmp-error-message", {
              class: { "range-filter-form__error-message": true },
              attrs: { message: "Please enter start or end value." }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "range-filter-form__buttons" }, [
          _c(
            "button",
            {
              staticClass: "button-main",
              attrs: { type: "button" },
              on: { click: _vm.clearFilters }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(this.getTranslation("Clear")) +
                  "\n            "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "button-main colored", attrs: { type: "submit" } },
            [
              _vm._v(
                "\n                " +
                  _vm._s(this.getTranslation("Apply")) +
                  "\n            "
              )
            ]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }