<template>
    <div class="driv-parts-result-filters-container">
        <div class="driv-parts-result-filters-desktop">
            <driv-parts-result-filters
                :onFilter="onFilter"
                :filters="filters"
                :initFilters="initFilters"
                :isPartTypesSearchAvailable="true"
                :validFiltersData="validFiltersData"
                :ignoreValidGroups="true"
                filtersTitle="Refine results"
            ></driv-parts-result-filters>
        </div>
        <div class="driv-parts-result-filters-mobile">
            <driv-section :title="getTranslation('Filter results')">
                <driv-parts-result-filters
                    :onFilter="handleMobileFilter"
                    :filters="filters"
                    :initFilters="initFilters"
                    :isPartTypesSearchAvailable="true"
                    :ignoreValidGroups="true"
                ></driv-parts-result-filters>
                <div class="driv-parts-result-filters-button-wrapper">
                    <a class="button-main" @click.prevent="() => onFilter(selectedFilters)"
                        ><fmmp-i18n text="Apply filters"
                    /></a>
                </div>
            </driv-section>
        </div>
    </div>
</template>

<script>
import { getHashParams, getSelectedFiltersFromData, replaceEmptyWithNull } from "./helpers";
import { FILTERS_QS_OPTIONS } from "./constants";
import { getTranslation } from "../../../common/partFinderCorporate.helpers";

export default Vue.component("driv-corp-parts-result-filters", {
    data() {
        return {
            selectedFilters: {
                categories: {},
                configs: {},
            },
            initFilters: null,
            validFiltersData: null,
        };
    },
    props: {
        filters: {
            type: Object,
            default: () => ({
                categories: [],
                configs: {},
                partTypes: [],
                brands: [],
            }),
        },
        dataForSelectedFilters: Object,
    },
    methods: {
        getTranslation,
        onFilter(selectedFilters) {
            const { configs, categories } = selectedFilters;
            this.selectedFilters = selectedFilters;

            const filters = {
                ...(Object.values(configs).length ? { configs } : {}),
                ...(Object.values(categories).length ? { categories } : {}),
            };

            const stringifiedParams = Qs.stringify(
                { filters: replaceEmptyWithNull(filters) },
                {
                    ...FILTERS_QS_OPTIONS,
                    encoder: (val) => {
                        if (typeof val === "number") return `${val}`;

                        return val;
                    },
                },
            );
            const { top } = document.body.getBoundingClientRect();

            window.location.hash = stringifiedParams;

            // when assigning '' to hash page scrolls up,
            // to prevent this save and restore current scroll position
            if (!stringifiedParams) window.scrollTo(0, -top);
        },
        handleMobileFilter(selectedFilters) {
            this.selectedFilters = selectedFilters;
        },
    },
    watch: {
        dataForSelectedFilters: {
            handler: function (val) {
                this.validFiltersData = getSelectedFiltersFromData(val);
            },
            deep: true,
        },
    },
    created() {
        if (!window.location.hash) return;

        let finalFilters = {};
        const { filters } = getHashParams(FILTERS_QS_OPTIONS);

        if (filters.configs) {
            finalFilters = Object.entries(filters.configs).reduce((result, [key, value]) => {
                const isFound = this.filters.configs.sub_configs.find(
                    (filter) => filter.type === key,
                );

                if (isFound) return { ...result, [key]: value };

                return result;
            }, {});
        }

        const allFilters = { filters: { ...filters, configs: finalFilters } };
        const stringifiedAllFilters = Qs.stringify(allFilters);

        if (window.location.hash !== stringifiedAllFilters) {
            const newUrl = window.location.href.replace(
                window.location.hash,
                `#${stringifiedAllFilters}`,
            );
            window.history.pushState({ urlPath: newUrl }, document.title, newUrl);
        }

        if (Object.keys(allFilters.filters).length) {
            this.initFilters = allFilters.filters;
            this.selectedFilters = allFilters.filters;
        }
    },
});
</script>
