<template>
    <ul class="driv-corp-list-view">
        <template v-if="isGurusProductInsights">
            <driv-gpi-list-view-item
                v-for="(product, i) in products"
                :key="i + product.part_number"
                :product="product"
                :detailsPath="detailsPath"
                :togglePartSelection="togglePartSelection"
                :isPartSelected="isPartSelected"
                :isReadOnly="isReadOnly"
            ></driv-gpi-list-view-item>
        </template>
        <template v-else>
            <driv-corp-list-view-item
                v-for="(product, i) in products"
                :key="i + product.part_number"
                :wtbPath="wtbPath"
                :product="product"
                :detailsPath="detailsPath"
                :hideWtbBtns="hideWtbBtns"
            ></driv-corp-list-view-item>
        </template>
    </ul>
</template>

<script>
export default Vue.component("driv-corp-list-view", {
    props: {
        products: {
            type: Array,
            default() {
                return [];
            },
        },
        wtbPath: {
            type: String,
            default: "",
        },
        detailsPath: {
            type: String,
            default: "",
        },
        hideWtbBtns: {
            type: Boolean,
            default: false,
        },
        // only for GPI
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        togglePartSelection: Function,
        isPartSelected: Function,
    },
    computed: {
        isGurusProductInsights() {
            return window.brand === "gpi";
        },
    },
});
</script>
