<template>
    <div class="driv-corp-grid-view-container" :key="componentKey">
        <div
            v-for="(productsGroup, index) in updatedProducts"
            class="driv-corp-grid-view-group"
            :key="productsGroup.brand + productsGroup.imageNotExist"
        >
            <div class="product-logo">
                <img
                    v-if="!productsGroup.imageNotExist"
                    :src="getBrandImgUrl(productsGroup)"
                    :alt="productsGroup.brand"
                    @error="onErrorLoad(index)"
                />
                <span v-else>{{ productsGroup.brand }}</span>
            </div>
            <table>
                <template v-for="(productType, i) in productsGroup.part_type_list">
                    <tr class="product-type-group" :key="productType.part_type_value">
                        <td :colspan="filteredTableColumns.length">
                            <fmmp-i18n :text="productType.part_type_value" />
                        </td>
                    </tr>
                    <tr class="product-columns" :key="`${productType.part_type_value}-${i}`">
                        <template v-for="column in filteredTableColumns">
                            <td :key="column.className" :class="`product-${column.className}`">
                                <fmmp-i18n v-if="column.label" :text="column.label" />
                                <i
                                    v-else-if="column.className === 'image'"
                                    class="fas fa-image"
                                ></i>
                            </td>
                        </template>
                    </tr>
                    <template v-for="(productInfo, i) in productType.parts_list">
                        <driv-gpi-grid-view-item
                            v-if="isGurusProductInsights"
                            :key="getUniqueKey(productInfo, i)"
                            :product="productInfo"
                            :detailsPath="detailsPath"
                            :columns="filteredTableColumns"
                            :toggleQuickDetails="
                                () => toggleQuickDetails(getUniqueKey(productInfo, i))
                            "
                            :isDetailsOpen="showQuickDetails === getUniqueKey(productInfo, i)"
                            :togglePartSelection="togglePartSelection"
                            :isPartSelected="isPartSelected"
                            :isReadOnly="isReadOnly"
                        ></driv-gpi-grid-view-item>
                        <driv-corp-grid-view-item
                            v-else
                            :key="getUniqueKey(productInfo, i)"
                            :product="productInfo"
                            :detailsPath="detailsPath"
                            :wtbPath="wtbPath"
                            :columns="filteredTableColumns"
                            :toggleQuickDetails="
                                () => toggleQuickDetails(getUniqueKey(productInfo, i))
                            "
                            :isDetailsOpen="showQuickDetails === getUniqueKey(productInfo, i)"
                            :hideWtbBtns="hideWtbBtns"
                        ></driv-corp-grid-view-item>

                        <template v-if="showQuickDetails === getUniqueKey(productInfo, i)">
                            <driv-quick-details-grid-view
                                :key="`${productInfo.item_id}-${i}-detail`"
                                :product="productInfo"
                                :toggleQuickDetails="toggleQuickDetails"
                                :detailsPath="detailsPath"
                                :showQuickDetails="getUniqueKey(productInfo, i)"
                            ></driv-quick-details-grid-view
                        ></template>
                    </template>
                </template>
            </table>
        </div>
    </div>
</template>

<script>
import { COLUMN_NAMES, SAVED_QUICK_DETAILS_PART_KEY, TABLE_COLUMNS } from "../constants";
import { getQsParams } from "../helpers";
import { getTranslation, getBrandImgUrl } from "../../../../common/partFinderCorporate.helpers";
import { PART_FINDER_CORPORATE_SEARCH_TYPES } from "../../../../common/partFinderCorporate.constants";

export default Vue.component("driv-corp-grid-view", {
    data() {
        return {
            tableColumns: TABLE_COLUMNS,
            showQuickDetails: null,
            componentKey: 0,
        };
    },
    props: {
        products: {
            type: Array,
            default() {
                return [];
            },
        },
        wtbPath: {
            type: String,
            default: "",
        },
        detailsPath: {
            type: String,
            default: "",
        },
        hideWtbBtns: {
            type: Boolean,
            default: false,
        },
        // only for GPI
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        togglePartSelection: Function,
        isPartSelected: Function,
    },
    computed: {
        updatedProducts() {
            return this.products.map((productsGroup) => {
                productsGroup.imageNotExist = false;
                return productsGroup;
            });
        },
        searchType() {
            const { searchType } = getQsParams();
            const isEngineLightDuty = searchType === PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE;
            const isEnginePerformance =
                searchType === PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE;
            const isEngine =
                searchType === PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_HEAVY_DUTY ||
                isEnginePerformance ||
                isEngineLightDuty;
            return { isEngine, isEngineLightDuty, isEnginePerformance };
        },
        isGurusProductInsights() {
            return window.brand === "gpi";
        },
        filteredTableColumns() {
            return this.tableColumns.filter((column) => {
                // if (this.isGurusProductInsights && !column.label) return false;
                if (this.isGurusProductInsights) {
                    if (!column.label && column.className === COLUMN_NAMES.WTB) return false;
                }
                if (this.hideWtbBtns && column.className === COLUMN_NAMES.WTB) return false;
                if (this.searchType.isEngineLightDuty && column.className === COLUMN_NAMES.POSITION)
                    return false;
                return !(
                    this.searchType.isEngine &&
                    (column.className === COLUMN_NAMES.SUB_MODEL ||
                        column.className === COLUMN_NAMES.DRIVE_WHEEL ||
                        column.className === COLUMN_NAMES.ENGINE_BASE ||
                        column.className === COLUMN_NAMES.ENGINE_VIN ||
			      column.className === COLUMN_NAMES.ENGINE_DESIGNATION)
                );
            });
        },
    },
    methods: {
        getBrandImgUrl,
        setQuickDetailsState(key) {
            if (this.showQuickDetails === key) {
                this.showQuickDetails = null;
                return;
            }
            this.showQuickDetails = key;
        },
        toggleQuickDetails(uniqueKey) {
            const setQuickDetailsState = this.setQuickDetailsState;

            Vue.Global.Analytics.trackEvent(
                "grid-view-part-results",
                "open-quick-details",
                uniqueKey,
                null,
                {
                    callback: function () {
                        setQuickDetailsState(uniqueKey);
                    },
                },
            );
        },
        getUniqueKey(product, i) {
            return `${product.part_type}-${product.part_number}-${product.brand}-${i}`;
        },
        onErrorLoad(index) {
            this.updatedProducts[index].imageNotExist = true;
            // force re-render
            this.componentKey += 1;
        },
        getPerformanceColumns() {
            this.tableColumns = this.tableColumns.map((column) =>
                column.className === COLUMN_NAMES.QUALIFIERS
                    ? { ...column, label: getTranslation("Application/Part Comments") }
                    : column,
            );
        },
        hasSavedQuickDetailsPartOnPage(products, savedShowQuickDetailsPart) {
            return !!this.products.find((productsGroup) =>
                productsGroup.part_type_list.find((partTypeList) =>
                    partTypeList.parts_list.find(
                        (part, i) => this.getUniqueKey(part, i) === savedShowQuickDetailsPart,
                    ),
                ),
            );
        },
    },
    created() {
        const savedShowQuickDetailsPart = sessionStorage.getItem(SAVED_QUICK_DETAILS_PART_KEY);
        if (savedShowQuickDetailsPart) {
            const hasSavedQuickDetails = this.hasSavedQuickDetailsPartOnPage(
                this.products,
                savedShowQuickDetailsPart,
            );
            if (hasSavedQuickDetails) {
                this.toggleQuickDetails(savedShowQuickDetailsPart);
            } else {
                sessionStorage.removeItem(SAVED_QUICK_DETAILS_PART_KEY);
            }
        }
        if (this.searchType.isEnginePerformance) this.getPerformanceColumns();
    },
});
</script>
