var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "driv-corp-item-part-name" }, [
    _c("div", { staticClass: "driv-corp-item-part-name-subheading" }, [
      _c(
        "div",
        { staticClass: "driv-corp-item-part-name-part-number-wrapper" },
        [
          _c("fmmp-i18n", { attrs: { text: "Part No" } }),
          _vm._v(":\n            "),
          _c(
            "a",
            {
              staticClass: "driv-corp-item-part-name-part-number",
              attrs: { rel: "noopener noreferrer", target: "_blank" },
              on: { click: _vm.handleGoToDetails }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.product.part_number) +
                  "\n            "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "driv-corp-item-part-name-brand" }, [
        _c("span", [_vm._v(_vm._s(_vm.product.brand_name))]),
        _vm._v(" "),
        _vm.product.sub_brand_name
          ? _c("span", { staticClass: "subbrand" }, [
              _vm._v(
                "\n                - " +
                  _vm._s(_vm.product.sub_brand_name) +
                  "\n            "
              )
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "driv-corp-item-part-name-title" }, [
      _c(
        "a",
        {
          attrs: { rel: "noopener noreferrer", target: "_blank" },
          on: { click: _vm.handleGoToDetails }
        },
        [_vm._v("\n            " + _vm._s(_vm.partName) + "\n        ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }