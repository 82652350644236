<template>
    <div class="update-case-btn">
        <button class="button-secondary" @click="onButtonClick" :disabled="isReadOnly">
            <fmmp-i18n text="Update case" />
        </button>
    </div>
</template>

<script>
export default Vue.component("driv-gpi-update-case-btn", {
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onButtonClick() {
            this.$emit("onClick");
        },
    },
});
</script>
