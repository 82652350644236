<template>
    <tr class="product__info">
        <td class="product-image">
            <img :src="imageSrc" :alt="product.part_name" />
        </td>
        <td class="product-name-container">
            <a
                class="product-name"
                @click="handleGoToDetails(product.part_number, product.brand, detailsPath)"
                rel="noopener noreferrer"
                target="_blank"
            >
                {{ product.part_number }}
            </a>
            <button
                v-if="hasDiagram"
                class="interactive-diagrams-btn"
                @click="openDiagramPage(product)"
            >
                <fmmp-i18n text="Interactive Diagrams" />
            </button>
            <div
                :class="['product-quick-details', { active: isDetailsOpen }]"
                @click="toggleQuickDetails"
            >
                <i class="fa fa-list"></i>
                <fmmp-i18n text="Quick Details" class="quick-details-text"></fmmp-i18n>
            </div>
        </td>
        <td class="product-submodel" v-if="checkIfArrayHasColumn(columnNames.SUB_MODEL)">
            {{
                transformToMissedValue(
                    typeof product.sub_model === "object" && product.sub_model.value,
                )
            }}
        </td>
        <td class="product-drive-wheel" v-if="checkIfArrayHasColumn(columnNames.DRIVE_WHEEL)">
            {{ transformToMissedValue(typeof product.drive === "object" && product.drive.value) }}
        </td>
        <td class="product-engine-base" v-if="checkIfArrayHasColumn(columnNames.ENGINE_BASE)">
            {{ transformToMissedValue(product.engine_base_value) }}
        </td>
        <td class="product-engine-vin" v-if="checkIfArrayHasColumn(columnNames.ENGINE_VIN)">
            {{ transformToMissedValue(product.engine_vin && product.engine_vin.value) }}
        </td>
        <td class="product-position" v-if="checkIfArrayHasColumn(columnNames.POSITION)">
            {{ transformToMissedValue(product.position && product.position.value) }}
        </td>
	  <td class="product-designation" v-if="checkIfArrayHasColumn(columnNames.ENGINE_DESIGNATION)">
            {{ transformToMissedValue(product.engine_designation && product.engine_designation.value) }}
        </td>
        <td
            :class="['product-qualifiers', { 'align-left': isQualifierAlignedLeft }]"
            v-if="checkIfArrayHasColumn(columnNames.QUALIFIERS)"
        >
            {{ transformToMissedValue(product.additional_fit_criteria) }}
        </td>
        <td class="product-vehicle-quantity" v-if="checkIfArrayHasColumn(columnNames.QUANTITY)">
            {{ transformToMissedValue(product.qty) }}
        </td>
        <td v-if="!hideWtbBtns" class="product-wtb">
            <span
                @click="
                    handleWtbClick({
                        partNumber: product.part_number,
                        brand: product.wtb_brand_name,
                    })
                "
            >
                <i class="fa fa-map-marker"></i>
            </span>
        </td>
    </tr>
</template>

<script>
import {
    PART_FINDER_CORPORATE_SEARCH_TYPES,
    WTB_EVENTS,
} from "../../../../common/partFinderCorporate.constants";
import { getCorporateWtbUrl, getQsParams, openDiagramPage } from "../helpers";
import {
    createUrlToGoToSearchResults,
    transformToMissedValue,
} from "../../../../common/partFinderCorporate.helpers";
import { COLUMN_NAMES } from "../constants";

const DEFAULT_IMG_SRC = "/content/dam/placeholders/catalog-part-image.png";

export default Vue.component("driv-corp-grid-view-item", {
    data() {
        return {
            columnNames: COLUMN_NAMES,
        };
    },
    props: {
        product: Object,
        detailsPath: {
            type: String,
            default: "",
        },
        toggleQuickDetails: {
            type: Function,
            default: () => {},
        },
        isDetailsOpen: {
            type: Boolean,
            default: false,
        },
        wtbPath: {
            type: String,
            default: "",
        },
        columns: {
            type: Array,
            default: [],
        },
        hideWtbBtns: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        imageSrc() {
            return this.product.dam_assets?.productPrimaries[0].url || DEFAULT_IMG_SRC;
        },
        performanceEngineSearchType() {
            const { searchType } = getQsParams();
            return searchType === PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE;
        },
        isQualifierAlignedLeft() {
            return this.product.additional_fit_criteria !== "N/A"
                ? this.product.additional_fit_criteria
                : false;
        },
        hasDiagram() {
            return this.product.digital_assets;
        },
    },
    methods: {
        transformToMissedValue,
        openDiagramPage,
        checkIfArrayHasColumn(columnClassName) {
            return this.columns.find((col) => columnClassName === col.className);
        },
        handleGoToDetails(partNumber, brandCode, path) {
            if (!path) return;

            Vue.Global.Analytics.trackEvent("Part Search Results Link", "Part", partNumber, null, {
                callback: function () {
                    if (window.brand === 'commercial-parts') {
                        window.location.assign(
                            `https://www.drivparts.com/part-details.html?part_number=${partNumber}&brand_code=${brandCode}`
                        );
                    } else {
                        window.location.assign(
                            createUrlToGoToSearchResults({
                                queryObj: { part_number: partNumber, brand_code: brandCode },
                                path,
                            })
                        );
                    }
                },
            });
        },
        handleWtbClick({ brand, partNumber }) {
            if (this.wtbPath) {
                const url = getCorporateWtbUrl({
                    brand,
                    wtbPath: this.wtbPath,
                });

                Vue.Global.Analytics.trackEvent(
                    "where-to-buy",
                    WTB_EVENTS.store,
                    partNumber,
                    null,
                    {
                        callback: function () {
                            window.location.assign(url);
                        },
                    },
                );
            }
        },
    },
});
</script>
