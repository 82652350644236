var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "driv-parts-result-filters-container" }, [
    _c(
      "div",
      { staticClass: "driv-parts-result-filters-desktop" },
      [
        _c("driv-parts-result-filters", {
          attrs: {
            onFilter: _vm.onFilter,
            filters: _vm.filters,
            initFilters: _vm.initFilters,
            isPartTypesSearchAvailable: true,
            validFiltersData: _vm.validFiltersData,
            ignoreValidGroups: true,
            filtersTitle: "Refine results"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "driv-parts-result-filters-mobile" },
      [
        _c(
          "driv-section",
          { attrs: { title: _vm.getTranslation("Filter results") } },
          [
            _c("driv-parts-result-filters", {
              attrs: {
                onFilter: _vm.handleMobileFilter,
                filters: _vm.filters,
                initFilters: _vm.initFilters,
                isPartTypesSearchAvailable: true,
                ignoreValidGroups: true
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "driv-parts-result-filters-button-wrapper" },
              [
                _c(
                  "a",
                  {
                    staticClass: "button-main",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return function() {
                          return _vm.onFilter(_vm.selectedFilters)
                        }.apply(null, arguments)
                      }
                    }
                  },
                  [_c("fmmp-i18n", { attrs: { text: "Apply filters" } })],
                  1
                )
              ]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }