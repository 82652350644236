var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "selected-diameter-info-page" },
    [
      _c("driv-ujoint-common-page-layout", {
        attrs: {
          title: _vm.title,
          instructionsText: _vm.instructions,
          imgSrc: _vm.imgSrc,
          noTabs: _vm.isTabsHidden
        },
        scopedSlots: _vm._u([
          {
            key: "inches-tab",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "specification-table" },
                  [
                    _c("fmmp-table", {
                      attrs: {
                        gridOptions: _vm.inTableProps,
                        tableData: _vm.inTableData,
                        columnConfig: _vm.inColConfig
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "millimeters-tab",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "specification-table" },
                  [
                    _c("fmmp-table", {
                      attrs: {
                        gridOptions: _vm.mmTableProps,
                        tableData: _vm.mmTableData,
                        columnConfig: _vm.mmColConfig
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }