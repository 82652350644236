var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { staticClass: "quick-details__row" }, [
    _c("td", { class: { loaded: !_vm.isLoading }, attrs: { colspan: "10" } }, [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "three-quarters-loader" },
            [_c("fmmp-i18n", { attrs: { text: "loading..." } })],
            1
          )
        : _c(
            "div",
            { staticClass: "quick-details__container" },
            [
              _c("i", {
                staticClass: "fa fa-plus-circle quick-details__close-icon",
                on: { click: _vm.toggleQuickDetails }
              }),
              _vm._v(" "),
              _vm.isError
                ? _c(
                    "div",
                    [
                      _c("fmmp-i18n", {
                        attrs: {
                          text: "Something went wrong. Please, try again later."
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "driv-tabs",
                    { attrs: { activeTabIndex: _vm.selectedTabIndex } },
                    [
                      _c(
                        "driv-tab",
                        {
                          attrs: {
                            name: _vm.getTranslation("Features"),
                            selected: true,
                            tabsOnMobile: true
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "quick-details__features-tab" },
                            [
                              _c("driv-part-detail-page-logo", {
                                attrs: { partDetail: _vm.partDetail }
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "quick-details__part-name" },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.partDetail.part_type) +
                                      "\n                            "
                                  ),
                                  _vm.partDetail.sub_brand_name
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "quick-details__sub-brand"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.partDetail.sub_brand_name
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _c("driv-part-detail-page-content", {
                                attrs: { partDetail: _vm.partDetail }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.partDetail.part_attributes &&
                      _vm.partDetail.part_attributes.length
                        ? _c(
                            "driv-tab",
                            {
                              attrs: {
                                name: _vm.getTranslation("Specifications"),
                                tabsOnMobile: true
                              }
                            },
                            [
                              _c("driv-part-detail-page-specifications", {
                                attrs: {
                                  partAttributes:
                                    _vm.partDetail.part_attributes,
                                  partType: _vm.partDetail.part_type
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.partDetail.kits && _vm.partDetail.kits.length
                        ? _c(
                            "driv-tab",
                            {
                              attrs: {
                                name: _vm.getTranslation("Contents"),
                                tabsOnMobile: true
                              }
                            },
                            [
                              _c("driv-part-detail-page-contents", {
                                attrs: {
                                  kits: _vm.partDetail.kits,
                                  detailsPagePath: _vm.detailsPath,
                                  showQuickDetails: _vm.showQuickDetails
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }