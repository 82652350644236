<template>
    <div class="u-joint-types-container">
        <div class="u-joint-types-cards">
            <div class="u-joint-card" v-for="type in uJointTypes" :key="type.attribute_name">
                <img :src="type.attribute_image_url" :alt="type.attribute_name" />
                <button class="button-main" @click="() => getFilteredResult(type)">
                    <fmmp-i18n text="select" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { SPECIFICATIONS_QS_OPTIONS, SPECIFICATION_PROPERTIES } from "../constants";
import { U_JOINT_ATTRIBUTE_NAME } from "../../../../common/specification-search/constants";

export default Vue.component("driv-corp-u-joint-types", {
    props: {
        uJointTypes: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        getFilteredResult(type) {
            const updatedFilters = {
                [SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS]: [
                    {
                        value: type.attribute_name,
                        name: U_JOINT_ATTRIBUTE_NAME,
                    },
                ],
            };

            const selectedType = updatedFilters[SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS][0];
            const eventParams = `${selectedType.value} | ${selectedType.name}`;

            Vue.Global.Analytics.trackEvent(
                "ujoint-results",
                "click-ujoint-type",
                eventParams,
                null,
                {
                    callback: function () {
                        window.location.hash = encodeURIComponent(
                            Qs.stringify(updatedFilters, SPECIFICATIONS_QS_OPTIONS),
                        );
                    },
                },
            );
        },
    },
});
</script>
