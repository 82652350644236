var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "driv-part-number-tab" },
    _vm._l(_vm.products, function(product) {
      return _c(
        "div",
        { key: product.part_name, staticClass: "driv-part-number-tab-item" },
        [
          _c(
            "div",
            { staticClass: "driv-part-number-tab-item-image list-desktop" },
            [
              _c("img", {
                attrs: {
                  src: _vm.getImageSrc(product.dam_assets),
                  alt: product.part_name
                }
              })
            ]
          ),
          _vm._v(" "),
          _vm.isGurusProductInsights
            ? _c("driv-gpi-part-name-section", {
                attrs: {
                  product: product,
                  partName: product.part_name,
                  togglePartSelection: _vm.togglePartSelection,
                  isPartSelected: _vm.isPartSelected,
                  attributeNameValue: _vm.checkboxAttrName.products,
                  isReadOnly: _vm.isReadOnly
                },
                on: {
                  goToDetails: function() {
                    return _vm.getPartDetail(
                      product.part_number,
                      product.brand,
                      _vm.detailsPath
                    )
                  }
                }
              })
            : _c("driv-corp-part-name-section", {
                attrs: {
                  handleGoToDetails: function() {
                    return _vm.getPartDetail(
                      product.part_number,
                      product.brand,
                      _vm.detailsPath
                    )
                  },
                  product: product,
                  partName: product.part_name
                }
              }),
          _vm._v(" "),
          !_vm.hideWtbBtns
            ? _c(
                "div",
                { staticClass: "driv-part-number-tab-item-ctas" },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "driv-part-number-tab-item-cta button-secondary",
                      on: {
                        click: function() {
                          return _vm.handleWtbClick({
                            brand: product.wtb_brand_name,
                            locType: "install",
                            partNumber: product.part_number
                          })
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.getTranslation("Get it installed")) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "driv-part-number-tab-item-cta button-secondary",
                      on: {
                        click: function() {
                          return _vm.handleWtbClick({
                            brand: product.wtb_brand_name,
                            locType: "store",
                            partNumber: product.part_number
                          })
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.getTranslation("Buy in store")) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  product.online_store_list && product.online_store_list.length
                    ? _c("driv-dropdown-button", {
                        attrs: {
                          buttonClasses:
                            "driv-part-number-tab-item-cta button-secondary",
                          text: _vm.getTranslation("Buy now"),
                          options: _vm.mapPartOnlineStoreToOptions(
                            product.online_store_list,
                            product.part_number
                          )
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }