var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ujoint-common-page" },
    [
      _c("div", { staticClass: "ujoint-common-page__image" }, [
        _c("img", { attrs: { src: _vm.imgSrc, alt: "" } })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ujoint-common-page__info info" },
        [
          _c(
            "div",
            { staticClass: "info__header" },
            [
              _c("fmmp-brandlogo", {
                attrs: {
                  option: _vm.brandLogo,
                  isResult: true,
                  containerClass: "ujoint-pages-brand-logo"
                },
                on: { onErrorImageLoad: _vm.onErrorImageLoad }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ujoint-common-page__image mobile" }, [
            _c("img", { attrs: { src: _vm.imgSrc, alt: "" } })
          ]),
          _vm._v(" "),
          _c("driv-tab", { attrs: { name: _vm.instructionsTitle, order: 0 } }, [
            _c("span", [_vm._v(" " + _vm._s(_vm.instructionsTitle) + " ")]),
            _vm._v(" "),
            _c("div", { domProps: { innerHTML: _vm._s(_vm.instructionsText) } })
          ])
        ],
        1
      ),
      _vm._v(" "),
      !_vm.noTabs
        ? _c(
            "driv-tabs",
            { staticClass: "ujoint-common-page__tabs" },
            [
              _c(
                "driv-tab",
                {
                  attrs: {
                    name: _vm.getTranslation("Inches"),
                    order: 0,
                    selected: true,
                    tabsOnMobile: true
                  }
                },
                [_vm._t("inches-tab")],
                2
              ),
              _vm._v(" "),
              _c(
                "driv-tab",
                {
                  attrs: {
                    name: _vm.getTranslation("Millimeters"),
                    order: 1,
                    tabsOnMobile: true
                  }
                },
                [_vm._t("millimeters-tab")],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }