var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading.initial
        ? _c(
            "div",
            { staticClass: "three-quarters-loader" },
            [_c("fmmp-i18n", { attrs: { text: "loading..." } })],
            1
          )
        : _vm.searchResultsType.partNumber
        ? _c("driv-corp-part-number-results", {
            attrs: {
              partsList: _vm.partNumberList,
              wtbPath: _vm.wtbPath,
              error: _vm.error,
              detailsPath: _vm.detailsPath,
              hideWtbBtns: _vm.hideWtbBtns,
              noPartsFound: _vm.noPartsFound,
              isPartSelected: _vm.isPartSelected,
              togglePartSelection: _vm.togglePartSelection,
              sendPartsData: _vm.sendPartsData,
              partsAmount: _vm.selectedParts.length,
              notificationModal: _vm.notificationModal,
              updateNotificationModalState: _vm.updateNotificationModalState
            }
          })
        : _vm.searchResultsType.uJoint
        ? _c("driv-corp-u-joint-results", {
            attrs: {
              uJointTypes: _vm.uJointTypes,
              uJointSpecifications: _vm.specificationsList,
              error: _vm.error,
              detailsPath: _vm.detailsPath,
              isLoading: _vm.isLoading.products
            }
          })
        : _vm.searchResultsType.specification
        ? _c("driv-corp-specification-results", {
            staticClass: "driv-corp-specification-results",
            attrs: {
              error: _vm.error,
              pagination: _vm.pagination,
              handlePageChange: _vm.handlePageChange,
              specifications: _vm.specificationsList,
              infoModal: _vm.modalText,
              detailsPath: _vm.detailsPath
            }
          })
        : _vm.searchResultsType.torque
        ? _c("driv-corp-torque-results", {
            attrs: {
              torqueYears: _vm.torqueYears,
              pdfImageSrc: _vm.torquePdfImageSrc,
              viewPdfRedirectLink: _vm.viewTorquePdfRedirectLink,
              pdfRedirectLinkText: _vm.pdfRedirectLinkText,
              hubPage1stLogo: _vm.torqueHubPage1stLogo,
              hubPage2ndLogo: _vm.torqueHubPage2ndLogo,
              cylinderPage1stLogo: _vm.torqueCylinderPage1stLogo,
              cylinderPage2ndLogo: _vm.torqueCylinderPage2ndLogo,
              torqueSpecRemark: _vm.torqueSpecRemark,
              torqueApiProviderLogo: _vm.torqueApiProviderLogo,
              torqueApiProviderText: _vm.torqueApiProviderText,
              torqueLinkText: _vm.torqueLinkText,
              torqueLinkPath: _vm.torqueLinkPath
            }
          })
        : _c(
            "div",
            { staticClass: "driv-part-results-content" },
            [
              _vm.showEngineSearchTable
                ? _c(
                    "div",
                    { staticClass: "driv-engine-search-table tablet desktop" },
                    [
                      _c("driv-engine-search-filtering-table", {
                        attrs: {
                          tableFilters: _vm.tableFilters,
                          updateResults: _vm.updateEngineFiltersTable,
                          enginePerformance: _vm.searchResultsType.performance,
                          engineHeavy: _vm.searchResultsType.heavy
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.startPageLoading
                ? _c(
                    "div",
                    { staticClass: "driv-engine-search-results" },
                    [
                      !_vm.isInteractiveDiagram
                        ? _c(
                            "div",
                            { staticClass: "results-summary mobile" },
                            [
                              !_vm.noPartsFound
                                ? _c(
                                    "span",
                                    { staticClass: "results-quantity mobile" },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.pagination.total) +
                                          "\n                    "
                                      ),
                                      _c("fmmp-i18n", {
                                        attrs: { text: "Part Results" }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isGurusProductInsights && !_vm.noPartsFound
                                ? _c("driv-gpi-update-case-btn", {
                                    attrs: {
                                      isReadOnly: _vm.isReadOnlyVersionGpi
                                    },
                                    on: { onClick: _vm.sendPartsData }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isPaginationShown
                                ? _c("driv-corp-list-pagination", {
                                    attrs: {
                                      savedPage: _vm.pagination.page,
                                      totalPages: _vm.pagesQty,
                                      onPageChange: _vm.handlePageChange
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isFiltersShown
                        ? _c("driv-corp-parts-result-filters", {
                            attrs: {
                              filters: _vm.filters,
                              dataForSelectedFilters: _vm.dataForSelectedFilters
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isGurusProductInsights &&
                      !_vm.isInteractiveDiagram &&
                      !_vm.noPartsFound
                        ? _c("driv-gpi-selected-parts-amount", {
                            attrs: { partsAmount: _vm.selectedParts.length }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showEngineSearchTable
                        ? _c("driv-engine-search-filtering-table", {
                            staticClass: "driv-engine-search-table mobile",
                            attrs: {
                              tableFilters: _vm.tableFilters,
                              updateResults: _vm.updateEngineFiltersTable,
                              enginePerformance:
                                _vm.searchResultsType.performance,
                              engineHeavy: _vm.searchResultsType.heavy
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isLoading.products
                        ? _c("div", { staticClass: "three-quarters-loader" })
                        : _vm.showErrorScreen
                        ? _c("driv-empty-part-results-page", {
                            attrs: { noPartsFound: _vm.noPartsFound }
                          })
                        : _c(
                            "div",
                            {
                              class: [
                                "driv-part-results-products-container",
                                {
                                  "full-width": !_vm.isFiltersShown
                                },
                                {
                                  "margin-container": _vm.isInteractiveDiagram
                                }
                              ]
                            },
                            [
                              _vm.isLoading.products
                                ? _c("div", {
                                    staticClass: "three-quarters-loader"
                                  })
                                : _vm.isInteractiveDiagram
                                ? _c("driv-corp-diagram-results", {
                                    attrs: {
                                      detailsPath: _vm.detailsPath,
                                      diagrams: _vm.interactiveDiagrams
                                    }
                                  })
                                : _vm.partsList.length
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "results-summary" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "results-quantity" },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(_vm.pagination.total) +
                                                "\n                            "
                                            ),
                                            _c("fmmp-i18n", {
                                              attrs: { text: "Part Results" }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.isGurusProductInsights
                                          ? _c("driv-gpi-update-case-btn", {
                                              attrs: {
                                                isReadOnly:
                                                  _vm.isReadOnlyVersionGpi
                                              },
                                              on: { onClick: _vm.sendPartsData }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.hideViewSwitcher
                                          ? _c(
                                              "div",
                                              { staticClass: "view-modes" },
                                              [
                                                _c("fmmp-i18n", {
                                                  attrs: { text: "View Mode:" }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "button",
                                                  {
                                                    class: {
                                                      active:
                                                        _vm.viewMode ===
                                                        _vm.VIEW_MODES.LIST
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.setViewMode(
                                                          _vm.VIEW_MODES.LIST
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "fa fa-list"
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "button",
                                                  {
                                                    class: {
                                                      active:
                                                        _vm.viewMode ===
                                                        _vm.VIEW_MODES.GRID
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.setViewMode(
                                                          _vm.VIEW_MODES.GRID
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "fa fa-th"
                                                    })
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isGurusProductInsights
                                          ? _c(
                                              "driv-gpi-selected-parts-amount",
                                              {
                                                attrs: {
                                                  partsAmount:
                                                    _vm.selectedParts.length
                                                }
                                              }
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isPaginationShown
                                          ? _c("driv-corp-list-pagination", {
                                              attrs: {
                                                savedPage: _vm.pagination.page,
                                                totalPages: _vm.pagesQty,
                                                onPageChange:
                                                  _vm.handlePageChange
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "results" },
                                      [
                                        _vm.isPartsListShown
                                          ? _c("driv-corp-list-view", {
                                              attrs: {
                                                products: _vm.partsList,
                                                wtbPath: _vm.wtbPath,
                                                detailsPath: _vm.detailsPath,
                                                hideWtbBtns: _vm.hideWtbBtns,
                                                togglePartSelection:
                                                  _vm.togglePartSelection,
                                                isPartSelected:
                                                  _vm.isPartSelected,
                                                isReadOnly:
                                                  _vm.isReadOnlyVersionGpi
                                              }
                                            })
                                          : _c("driv-corp-grid-view", {
                                              attrs: {
                                                products: _vm.partsList,
                                                wtbPath: _vm.wtbPath,
                                                detailsPath: _vm.detailsPath,
                                                hideWtbBtns: _vm.hideWtbBtns,
                                                togglePartSelection:
                                                  _vm.togglePartSelection,
                                                isPartSelected:
                                                  _vm.isPartSelected,
                                                isReadOnly:
                                                  _vm.isReadOnlyVersionGpi
                                              }
                                            })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.isPaginationShown
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "results-summary footer"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "results-quantity"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                            " +
                                                    _vm._s(
                                                      _vm.pagination.total
                                                    ) +
                                                    "\n                            "
                                                ),
                                                _c("fmmp-i18n", {
                                                  attrs: {
                                                    text: "Part Results"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("driv-corp-list-pagination", {
                                              attrs: {
                                                savedPage: _vm.pagination.page,
                                                totalPages: _vm.pagesQty,
                                                onPageChange: function(v) {
                                                  return _vm.handlePageChange(
                                                    v,
                                                    true
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "driv-notification-modal",
                {
                  attrs: {
                    notificationModal: _vm.notificationModal,
                    modalTitle: "Part Details"
                  },
                  on: { updateModalState: _vm.updateNotificationModalState }
                },
                [
                  _c(
                    "div",
                    { staticClass: "modal-body" },
                    [
                      _c("fmmp-i18n", {
                        staticClass: "modal-text",
                        attrs: {
                          text: "Max 10 items can be selected at a time."
                        }
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("fmmp-i18n", {
                        staticClass: "modal-text bold",
                        attrs: { text: "10 out of 10 are selected" }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }