var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "refine-search-fields" },
    [
      _vm._l(_vm.options, function(field) {
        return _c("fmmp-autocomplete", {
          key: field,
          class: "driv-part-common " + field,
          attrs: {
            config: _vm.autocompleteConfig,
            id: field,
            name: field,
            items: _vm.fieldsConfig[field].items,
            disabled: _vm.fieldsConfig[field].disabled,
            placeholder: _vm.getTranslation(
              _vm.fieldsConfig[field].placeholder
            ),
            loading: _vm.fieldsConfig[field].loading,
            showLabel: _vm.isGurusProductInsights
          },
          on: {
            input: function(value) {
              return _vm.optionsConfig.fields[field].onInput(value)
            }
          },
          model: {
            value: _vm.fieldsConfig[field].model,
            callback: function($$v) {
              _vm.$set(_vm.fieldsConfig[field], "model", $$v)
            },
            expression: "fieldsConfig[field].model"
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }