<template>
    <div class="bearing-diameters-page">
        <driv-ujoint-common-page-layout
            :title="title"
            :instructionsText="instructions"
            :imgSrc="imgSrc"
            :noTabs="isTabsHidden"
        >
            <template v-slot:inches-tab>
                <ul class="list-container">
                    <li
                        class="list-item"
                        v-for="inch in inches"
                        :key="inch.value"
                        @click="getFilteredResult(inch)"
                    >
                        {{ inch.value }}
                    </li>
                </ul>
            </template>
            <template v-slot:millimeters-tab>
                <ul class="list-container">
                    <li
                        class="list-item millimeters"
                        v-for="mm in millimeters"
                        :key="mm.value"
                        @click="getFilteredResult(mm)"
                    >
                        {{ mm.value }}
                    </li>
                </ul>
            </template>
        </driv-ujoint-common-page-layout>
    </div>
</template>

<script>
import { getTranslation } from "../../../../common/partFinderCorporate.helpers";
import { SPECIFICATIONS_QS_OPTIONS, SPECIFICATION_PROPERTIES } from "../constants";
import { getDoubleEncodedHashParams, getFilterValues } from "../helpers";

export default Vue.component("driv-bearing-diameters-page", {
    props: {
        specifications: {
            type: Object,
            default: {},
        },
    },
    data() {
        return {
            imgSrc: "",
            title: "",
            instructions: null,
            currentFilter: {},
            inches: [],
            millimeters: [],
        };
    },
    computed: {
        isTabsHidden() {
            return (
                !this.specifications[SPECIFICATION_PROPERTIES.MASTER_LIST_PROPERTY]?.length ||
                !(this.inches?.length || this.millimeters?.length)
            );
        },
    },
    watch: {
        specifications: {
            handler: function () {
                this.setSpecificationsData();
            },
        },
    },
    methods: {
        setSpecificationsData() {
            if (this.specifications.additional_filters) {
                const { INCH, MM } = this.specifications.additional_filters;
                this.inches = INCH || [];
                this.millimeters = MM || [];
                const uJointAttributes = (this.specifications[
                    SPECIFICATION_PROPERTIES.UJOINT_ATTRIBUTES
                ] || [])[0];
                if (uJointAttributes) {
                    this.title = uJointAttributes.attribute_name;
                    this.instructions = uJointAttributes.instruction;
                    this.imgSrc = uJointAttributes.attribute_image_url;
                }
            }
        },
        getTranslation,
        prepareEventParamsForAnalytics(updatedFilters) {
            const selectedDiameter = updatedFilters[SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS];
            const diameterValues = selectedDiameter.reduce((acc, item) => {
                if (item.uom) {
                    acc = [...acc, `${item.name} ~ ${item.value} ${item.uom}`];
                }
                return [...acc];
            }, []);

            return [
                `${selectedDiameter[0].name} ~ ${selectedDiameter[0].value}`,
                ...diameterValues,
            ].join("|");
        },
        getFilteredResult(value) {
            const updatedFilters = {
                [SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS]: [
                    ...this.currentFilter[SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS],
                    ...getFilterValues(value),
                ],
            };

            const eventParams = this.prepareEventParamsForAnalytics(updatedFilters);

            Vue.Global.Analytics.trackEvent(
                "ujoint-results",
                "click-bearing-diameter",
                eventParams,
                null,
                {
                    callback: function () {
                        window.location.hash = encodeURIComponent(
                            Qs.stringify(updatedFilters),
                            SPECIFICATIONS_QS_OPTIONS,
                        );
                    },
                },
            );
        },
    },
    created() {
        this.currentFilter = getDoubleEncodedHashParams(SPECIFICATIONS_QS_OPTIONS);
        this.setSpecificationsData();
    },
});
</script>
