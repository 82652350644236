<template>
    <div class="part-number-results-wrapper">
        <driv-empty-part-results-page
            :noPartsFound="noPartsFound"
            v-if="showErrorScreen"
        ></driv-empty-part-results-page>
        <div v-else class="part-number-results-content">
            <div v-if="isGurusProductInsights" class="part-number-results-selection-info">
                <driv-gpi-selected-parts-amount
                    :partsAmount="partsAmount"
                ></driv-gpi-selected-parts-amount>
                <driv-gpi-update-case-btn
                    @onClick="sendPartsData"
                    :isReadOnly="isReadOnlyVersionGpi"
                ></driv-gpi-update-case-btn>
            </div>
            <driv-tabs>
                <driv-tab :name="partsTabName" :selected="true" v-if="partsList.products.length">
                    <driv-part-number-tab
                        :wtbPath="wtbPath"
                        :products="partsList.products"
                        :detailsPath="detailsPath"
                        :hideWtbBtns="hideWtbBtns"
                        :isPartSelected="isPartSelected"
                        :togglePartSelection="togglePartSelection"
                        :isReadOnly="isReadOnlyVersionGpi"
                    ></driv-part-number-tab>
                </driv-tab>
                <driv-tab :name="interchangesTabName" v-if="partsList.interchange_products.length">
                    <driv-interchanges-tab
                        :products="partsList.interchange_products"
                        :detailsPath="detailsPath"
                        :isPartSelected="isPartSelected"
                        :togglePartSelection="togglePartSelection"
                        :isReadOnly="isReadOnlyVersionGpi"
                    ></driv-interchanges-tab>
                </driv-tab>
            </driv-tabs>
        </div>

        <driv-notification-modal
            :notificationModal="notificationModal"
            modalTitle="Part Details"
            @updateModalState="updateNotificationModalState"
        >
            <div class="modal-body">
                <fmmp-i18n text="Max 10 items can be selected at a time." class="modal-text" />
                <br />
                <fmmp-i18n text="10 out of 10 are selected" class="modal-text bold" />
            </div>
        </driv-notification-modal>
    </div>
</template>

<script>
import { getTranslation } from "../../../../common/partFinderCorporate.helpers";
import { getQsParams } from "../helpers";

export default Vue.component("driv-corp-part-number-results", {
    props: {
        wtbPath: {
            type: String,
            default: "",
        },
        partsList: {
            type: Object,
            default: {
                interchange_products: [],
                products: [],
            },
        },
        error: {
            type: Boolean,
            default: false,
        },
        detailsPath: {
            type: String,
            default: "",
        },
        hideWtbBtns: {
            type: Boolean,
            default: false,
        },
        noPartsFound: {
            type: Boolean,
            default: false,
        },
        isPartSelected: Function,
        togglePartSelection: Function,
        sendPartsData: Function,
        partsAmount: {
            type: Number,
            default: 0,
        },
        notificationModal: {
            type: Boolean,
            default: false,
        },
        updateNotificationModalState: Function,
    },
    computed: {
        partsTabName() {
            return this.getTabNameWithQty(
                getTranslation("Our parts ({0})"),
                this.partsList.products.length,
            );
        },
        interchangesTabName() {
            return this.getTabNameWithQty(
                getTranslation("Interchange ({0})"),
                this.partsList.interchange_products.length,
            );
        },
        showErrorScreen() {
            return this.error || this.noPartsFound;
        },
        isGurusProductInsights() {
            return window.brand === "gpi";
        },
        isReadOnlyVersionGpi() {
            const { signed_request } = getQsParams();
            return !signed_request;
        },
    },
    methods: {
        getTabNameWithQty(tabName, qty) {
            return tabName.replace("{0}", qty);
        },
    },
});
</script>
