var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bearing-diameters-page" },
    [
      _c("driv-ujoint-common-page-layout", {
        attrs: {
          title: _vm.title,
          instructionsText: _vm.instructions,
          imgSrc: _vm.imgSrc,
          noTabs: _vm.isTabsHidden
        },
        scopedSlots: _vm._u([
          {
            key: "inches-tab",
            fn: function() {
              return [
                _c(
                  "ul",
                  { staticClass: "list-container" },
                  _vm._l(_vm.inches, function(inch) {
                    return _c(
                      "li",
                      {
                        key: inch.value,
                        staticClass: "list-item",
                        on: {
                          click: function($event) {
                            return _vm.getFilteredResult(inch)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(inch.value) +
                            "\n                "
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            },
            proxy: true
          },
          {
            key: "millimeters-tab",
            fn: function() {
              return [
                _c(
                  "ul",
                  { staticClass: "list-container" },
                  _vm._l(_vm.millimeters, function(mm) {
                    return _c(
                      "li",
                      {
                        key: mm.value,
                        staticClass: "list-item millimeters",
                        on: {
                          click: function($event) {
                            return _vm.getFilteredResult(mm)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(mm.value) +
                            "\n                "
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }