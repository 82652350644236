var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "u-joint-types-container" }, [
    _c(
      "div",
      { staticClass: "u-joint-types-cards" },
      _vm._l(_vm.uJointTypes, function(type) {
        return _c(
          "div",
          { key: type.attribute_name, staticClass: "u-joint-card" },
          [
            _c("img", {
              attrs: { src: type.attribute_image_url, alt: type.attribute_name }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button-main",
                on: {
                  click: function() {
                    return _vm.getFilteredResult(type)
                  }
                }
              },
              [_c("fmmp-i18n", { attrs: { text: "select" } })],
              1
            )
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }