<template>
    <div class="refine-search-fields">
        <template>
            <fmmp-autocomplete
                v-for="field in options"
                :key="field"
                :class="`driv-part-common ${field}`"
                :config="autocompleteConfig"
                :id="field"
                :name="field"
                :items="fieldsConfig[field].items"
                v-model="fieldsConfig[field].model"
                :disabled="fieldsConfig[field].disabled"
                :placeholder="getTranslation(fieldsConfig[field].placeholder)"
                :loading="fieldsConfig[field].loading"
                @input="(value) => optionsConfig.fields[field].onInput(value)"
                :showLabel="isGurusProductInsights"
            ></fmmp-autocomplete>
        </template>
    </div>
</template>

<script>
import { getTranslation } from "../../../../common/partFinderCorporate.helpers";
import configs from "./TorqueYmmSearchConfig";
import { getOptionsConfigByContentSilosValue, getOptionsFromResponse } from "./helpers";
import { FIELDS } from "./constants";
import { getQsParams } from "../helpers";

const { fieldsConfig, optionsConfig, configForUrlChange } = configs;

export default Vue.component("driv-refine-torque-search", {
    data() {
        return {
            autocompleteConfig: {
                minLength: 0,
                delay: 0,
                html: false,
                maxHeight: "300px",
                onlySelectValid: true,
                searchOnFocus: false,
            },
            optionsConfig: getOptionsConfigByContentSilosValue(optionsConfig),
            fieldsConfig,
            yearFieldItems: [],
            resolveNextField: true,
        };
    },
    props: {
        torqueYears: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        options() {
            return Object.keys(this.optionsConfig.fields).filter(
                (option) => this.fieldsConfig[option],
            );
        },
        isGurusProductInsights() {
            return window.brand === "gpi";
        },
    },
    methods: {
        getTranslation,
        setTorqueOptionsValue() {
            const searchData = {
                [FIELDS.YEAR]: configForUrlChange.searchParams.year,
                [FIELDS.MAKE]: configForUrlChange.searchParams.make,
                [FIELDS.MODEL]: configForUrlChange.searchParams.model,
                [FIELDS.ENGINE]: configForUrlChange.searchParams.engine,
            };

            configForUrlChange.options.forEach((option) => {
                if (!searchData[option]?.value) return;

                this.fieldsConfig[option].model = searchData[option];
                this.fieldsConfig[option].disabled = false;
            });
        },
    },
    watch: {
        yearFieldItems: function (mappedYears) {
            if (mappedYears.length === 1) {
                const resolveAllDependentFields =
                    configForUrlChange.searchParams.year?.value === mappedYears[0][FIELDS.YEAR];
                this.fieldsConfig[FIELDS.YEAR].model = mappedYears[0][FIELDS.YEAR];

                this.optionsConfig.onInput(this.torqueYears, resolveAllDependentFields);
                resolveAllDependentFields && this.setTorqueOptionsValue();
            } else if (configForUrlChange.searchParams.year?.value) {
                const option = mappedYears.find(
                    ({ value }) => value === +configForUrlChange.searchParams.year.value,
                );

                if (option) {
                    this.fieldsConfig[FIELDS.YEAR].model = option;
                    this.optionsConfig.fields[FIELDS.YEAR].onInput(option, this.resolveNextField);
                }
            }
        },
    },
    created() {
        configForUrlChange.options = this.options;

        const params = getQsParams();
        const { searchType, contentSilos, specificationType, make, model, year, engine } = params;

        configForUrlChange.searchParams = {
            searchType: decodeURIComponent(searchType || ""),
            specificationType: {
                label: decodeURIComponent(specificationType?.label || ""),
                value: decodeURIComponent(specificationType?.value || ""),
            },
            contentSilos: {
                label: decodeURIComponent(contentSilos?.label || ""),
                value: decodeURIComponent(contentSilos?.value || ""),
            },
            year: {
                label: decodeURIComponent(year?.label || ""),
                value: decodeURIComponent(year?.value || ""),
            },
            make: {
                label: decodeURIComponent(make?.label || ""),
                value: decodeURIComponent(make?.value || ""),
            },
            model: {
                label: decodeURIComponent(model?.label || ""),
                value: decodeURIComponent(model?.value || ""),
            },
            engine: {
                label: decodeURIComponent(engine?.label || ""),
                value: decodeURIComponent(engine?.value || ""),
            },
        };

        if (this.torqueYears) {
            this.optionsConfig.onInput(this.torqueYears);
            this.yearFieldItems = this.torqueYears.map(
                getOptionsFromResponse([FIELDS.YEAR], [FIELDS.YEAR]),
            );
        }

        this.setTorqueOptionsValue();
    },
});
</script>
