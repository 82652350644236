<template>
    <div class="driv-gpi-item-part-name">
        <div class="driv-gpi-item-part-name-title">
            <input
                type="checkbox"
                class="select-part"
                :id="customId"
                :name="attributeNameValue"
                :checked="isPartSelected(customId)"
                :disabled="isReadOnly"
                @click="togglePartSelection($event)"
            />
            <a @click="goToPartDetailsPage" rel="noopener noreferrer" target="_blank">
                {{ partName }}
            </a>
        </div>
        <div class="driv-gpi-item-part-name-subheading">
            <div class="driv-gpi-item-part-name-part-number">
                <fmmp-i18n text="Part No" />:
                <a @click="goToPartDetailsPage" rel="noopener noreferrer" target="_blank">
                    {{ product.part_number }}
                </a>
            </div>
            <div class="driv-gpi-item-part-name-brand">
                <fmmp-i18n text="Brand" />:
                <span>{{ product.brand_name }}</span>
            </div>
            <div class="driv-gpi-item-part-name-subbrand" v-if="product.sub_brand_name">
                <fmmp-i18n text="Subbrand" />:
                <span>{{ product.sub_brand_name }}</span>
            </div>
            <div :class="['driv-gpi-item-part-name-notes', hasPartNotes && 'has-part-notes']">
                <fmmp-i18n text="Part Notes" />
            </div>
        </div>
    </div>
</template>

<script>
import { generateCustomId } from "../helpers";

export default Vue.component("driv-gpi-part-name-section", {
    props: {
        togglePartSelection: Function,
        isPartSelected: {
            type: Function,
            default: () => {},
        },
        product: Object,
        partName: {
            type: String,
            default: "",
        },
        attributeNameValue: {
            type: String,
            default: "",
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        customId() {
            return generateCustomId(this.product);
        },
        hasPartNotes() {
            return this.product?.gpi_notes?.note_count;
        },
    },
    methods: {
        goToPartDetailsPage() {
            this.$emit("goToDetails");
        },
    },
});
</script>
