var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.notificationModal
    ? _c(
        "fmmp-modal",
        {
          attrs: { rootClass: "driv-notification-modal" },
          on: { close: _vm.closeModal }
        },
        [
          _c("div", { staticClass: "modal-header" }),
          _vm._v(" "),
          _c(
            "template",
            { slot: "title" },
            [_c("fmmp-i18n", { attrs: { text: _vm.modalTitle } })],
            1
          ),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }