var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.componentKey },
    [
      _vm.isErrorMessageShown
        ? _c("fmmp-i18n", {
            staticClass: "error-message",
            attrs: { text: "no torque specification data found" }
          })
        : !_vm.loading
        ? _c("div", [
            _c(
              "div",
              {
                class: ["driv-torque-table", { shortened: !_vm.isCylinderHead }]
              },
              [
                _vm.specifications.length === 1
                  ? _c("div", { staticClass: "torque-one-result-table" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              _vm.goToTorqueDetails({
                                baseId: _vm.baseId,
                                documentId: _vm.documentIdForOneResultTable,
                                appId: _vm.specifications[0].ApplicationID,
                                qualifierId: _vm.getQualifierId(
                                  _vm.specifications[0]
                                )
                              })
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-wrench desktop" }),
                          _vm._v(" "),
                          _c("fmmp-i18n", {
                            staticClass: "table-instruction desktop",
                            attrs: { text: _vm.instructionDesktop }
                          }),
                          _vm._v(" "),
                          _c("fmmp-i18n", {
                            staticClass: "table-instruction mobile",
                            attrs: { text: _vm.instructionMobile }
                          })
                        ],
                        1
                      )
                    ])
                  : _c(
                      "div",
                      { staticClass: "torque-all-results-table" },
                      [
                        _c("fmmp-i18n", {
                          staticClass: "table-instruction desktop",
                          attrs: { text: _vm.instructionDesktop }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "torque-results-table" }, [
                          _c("table", [
                            _c("thead", [
                              _c(
                                "tr",
                                [
                                  _vm._l(_vm.columnNames, function(column) {
                                    return [
                                      _c("th", { key: column }, [
                                        _vm._v(
                                          _vm._s(_vm.getTranslation(column))
                                        )
                                      ])
                                    ]
                                  })
                                ],
                                2
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              [
                                _vm._l(_vm.specifications, function(row, i) {
                                  return [
                                    _c(
                                      "tr",
                                      { key: i },
                                      [
                                        _vm._l(_vm.getDocs(row), function(
                                          document
                                        ) {
                                          return [
                                            _c(
                                              "td",
                                              { key: document.DocumentID },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        _vm.goToTorqueDetails({
                                                          baseId: _vm.baseId,
                                                          documentId:
                                                            document.DocumentID,
                                                          appId:
                                                            row.ApplicationID,
                                                          qualifierId: _vm.getQualifierId(
                                                            row
                                                          )
                                                        })
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-wrench desktop"
                                                    }),
                                                    _vm._v(" "),
                                                    _c("fmmp-i18n", {
                                                      staticClass: "mobile",
                                                      attrs: {
                                                        text:
                                                          _vm.instructionMobile
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        }),
                                        _vm._v(" "),
                                        row.SubModel
                                          ? _c("td", [
                                              _c(
                                                "span",
                                                { staticClass: "label mobile" },
                                                [
                                                  _c("fmmp-i18n", {
                                                    attrs: { text: "Submodel" }
                                                  }),
                                                  _vm._v(
                                                    _vm._s(":") +
                                                      "\n                                        "
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "value" },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.transformToMissedValue(
                                                          row.SubModel &&
                                                            row.SubModel
                                                              .SubModelName
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.checkForNotesOrProcsPresence(row) &&
                                        _vm.isCylinderHead
                                          ? _c("td", [
                                              _c(
                                                "span",
                                                { staticClass: "label mobile" },
                                                [
                                                  _c("fmmp-i18n", {
                                                    attrs: {
                                                      text: "Specifications"
                                                    }
                                                  }),
                                                  _vm._v(
                                                    _vm._s(":") +
                                                      "\n                                        "
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "value" },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.transformToMissedValue(
                                                          _vm.concatenateSpecString(
                                                            row
                                                          )
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        row.Engine
                                          ? _c("td", [
                                              _c(
                                                "span",
                                                { staticClass: "label mobile" },
                                                [
                                                  _c("fmmp-i18n", {
                                                    attrs: {
                                                      text: "Engine Version"
                                                    }
                                                  }),
                                                  _vm._v(
                                                    _vm._s(":") +
                                                      "\n                                        "
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "value" },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.transformToMissedValue(
                                                          row.Engine &&
                                                            row.Engine
                                                              .engineVersion
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        row.Qualifiers
                                          ? _c("td", [
                                              _c(
                                                "span",
                                                { staticClass: "label mobile" },
                                                [
                                                  _c("fmmp-i18n", {
                                                    attrs: {
                                                      text: "Qualifiers"
                                                    }
                                                  }),
                                                  _vm._v(
                                                    _vm._s(":") +
                                                      "\n                                        "
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "value" },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.transformToMissedValue(
                                                          row.Qualifiers
                                                            .length &&
                                                            row.Qualifiers[0]
                                                              .Description
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        ])
                      ],
                      1
                    )
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }