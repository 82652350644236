<template>
    <div class="ujoint-results">
        <driv-empty-part-results-page v-if="isErrorShown"></driv-empty-part-results-page>
        <template v-else>
            <ul class="ujoint-stepper">
                <li
                    v-for="step in steps"
                    :key="step.value"
                    @click="() => handleStepperClick(step.value)"
                >
                    {{ step.label }}
                </li>
            </ul>
            <div v-if="isLoading" class="three-quarters-loader" />
            <component
                v-else
                :is="displayedPageComponent"
                :uJointTypes="uJointTypes"
                :specifications="uJointSpecifications"
                :detailsPath="detailsPath"
            ></component>
        </template>
    </div>
</template>

<script>
import { getTranslation } from "../../../../common/partFinderCorporate.helpers";
import { SPECIFICATIONS_QS_OPTIONS, SPECIFICATION_PROPERTIES } from "../constants";
import {
    getDoubleEncodedHashParams,
    getSelectedDiameterValue,
    getUJointFilterAttribute,
    isUJointFilters,
    isUJointFiltersValue,
} from "../helpers";

const UJOINT_STEPS = {
    TYPE: "type",
    DIAMETER: "diameter",
    INFO: "info",
};
const UJOINT_PAGES = {
    [UJOINT_STEPS.TYPE]: "driv-corp-u-joint-types",
    [UJOINT_STEPS.DIAMETER]: "driv-bearing-diameters-page",
    [UJOINT_STEPS.INFO]: "driv-corp-u-joint-selected-diameter-info-page",
};
const MAP_STEPS = {
    [UJOINT_STEPS.TYPE]: [],
    [UJOINT_STEPS.DIAMETER]: [UJOINT_STEPS.TYPE, UJOINT_STEPS.DIAMETER],
    [UJOINT_STEPS.INFO]: [UJOINT_STEPS.TYPE, UJOINT_STEPS.DIAMETER, UJOINT_STEPS.INFO],
};

export default Vue.component("driv-corp-u-joint-results", {
    data() {
        return {
            currentStep: UJOINT_STEPS.TYPE,
            [UJOINT_STEPS.TYPE]: getTranslation("U-JOINT Type"),
            [UJOINT_STEPS.DIAMETER]: "",
            [UJOINT_STEPS.INFO]: "",
        };
    },
    props: {
        error: {
            type: Boolean,
            default: false,
        },
        uJointTypes: {
            type: Array,
            default: () => [],
        },
        uJointSpecifications: {
            type: Object,
            default: () => ({}),
        },
        detailsPath: {
            type: String,
            default: "",
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        displayedPageComponent() {
            return UJOINT_PAGES[this.currentStep];
        },
        steps() {
            return MAP_STEPS[this.currentStep].map((step) => ({ label: this[step], value: step }));
        },
        isErrorShown() {
            if (this.currentStep === UJOINT_STEPS.TYPE) return !this.uJointTypes?.length;
            return this.error;
        },
    },
    methods: {
        handleUrlChange({ newURL, oldURL }) {
            // hash event fires twice,
            // because url is decoded to look readable (object properties in [])
            if (!!newURL && !!oldURL && decodeURI(newURL) === decodeURI(oldURL)) return;

            this.calcCurrentStep();
        },
        calcCurrentStep() {
            const filters = getDoubleEncodedHashParams(SPECIFICATIONS_QS_OPTIONS);
            const attributeFilters = (filters || {})[SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS];
             if (!attributeFilters || isUJointFiltersValue(filters)) {
                this.currentStep = UJOINT_STEPS.TYPE;
                return;
            }

            if (attributeFilters.length === 1 && isUJointFilters(filters)) {
                this.currentStep = UJOINT_STEPS.DIAMETER;
                this.updateStepsValues(this.currentStep, attributeFilters[0].value);
                return;
            }

            this.currentStep = UJOINT_STEPS.INFO;
            this.updateStepsValues(this.currentStep, getSelectedDiameterValue(attributeFilters));
            if (!this[UJOINT_STEPS.DIAMETER]) {
                const uJointTypeAttr = getUJointFilterAttribute(attributeFilters);
                this.updateStepsValues(UJOINT_STEPS.DIAMETER, uJointTypeAttr?.value);
            }
        },
        updateStepsValues(step, value) {
            this.$set(this, step, value);
        },
        handleStepperClick(step) {
            switch (step) {
                case UJOINT_STEPS.TYPE: {
                    window.location.hash = "";
                    break;
                }
                case UJOINT_STEPS.DIAMETER: {
                    const filters = getDoubleEncodedHashParams(SPECIFICATIONS_QS_OPTIONS);
                    const attributeFilters = (filters || {})[
                        SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS
                    ];
                    const uJointTypeAttr = getUJointFilterAttribute(attributeFilters);
                    const eventParams = `${UJOINT_STEPS.DIAMETER} | ${uJointTypeAttr.value} ~ ${uJointTypeAttr.name}`;

                    Vue.Global.Analytics.trackEvent(
                        "ujoint-results",
                        "click-ujoint-stepper",
                        eventParams,
                        null,
                        {
                            callback: function () {
                                window.location.hash = encodeURIComponent(
                                    Qs.stringify({
                                        [SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS]: [
                                            uJointTypeAttr,
                                        ],
                                    }),
                                    SPECIFICATIONS_QS_OPTIONS,
                                );
                            },
                        },
                    );

                    break;
                }
                case UJOINT_STEPS.INFO:
                    break;
                default:
                    break;
            }
        },
    },
    created() {
        window.addEventListener("hashchange", this.handleUrlChange, false);
        this.calcCurrentStep();
    },
    destroyed() {
        window.removeEventListener("hashchange", this.handleUrlChange, false);
    },
});
</script>
