<template>
    <div class="table-container">
        <table class="driv-engine-search-filtering-table desktop">
            <thead>
                <tr>
                    <th>
                        <fmmp-i18n text="select" />
                    </th>
                    <th>
                        <span>{{ getTableHeaderData() }}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(filter, i) in tableFilters" :key="i" class="filter">
                    <td class="checkbox">
                        <input
                            type="radio"
                            :checked="filter.id === selectedRow"
                            @click="handleRadioBtnClick(i)"
                        />
                    </td>
                    <td>
                        {{ transformToMissedValue(filter.value && filter.value) }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="driv-engine-search-filtering-table mobile">
            <div class="version-props">
                <span>
                    <b class="name">
                        <fmmp-i18n text="Engine versions:" />
                    </b>
                    {{ transformToMissedValue(tableFilters[0] && tableFilters[0].value) }}
                </span>
            </div>
            <button class="button-main select" @click="showMobileSelector = true">
                <fmmp-i18n text="Select version" />
            </button>
            <fmmp-modal
                v-if="showMobileSelector"
                rootClass="driv-engine-search-filtering-table modal"
                @close="showMobileSelector = false"
            >
                <template slot="title">
                    <span>{{ getTableHeaderData() }}</span>
                </template>
                <div v-for="(filter, i) in tableFilters" :key="i" class="version-container">
                    <ul class="version-props">
                        <li v-for="prop in engineVersionProps" :key="prop.label">
                            <b class="name">
                                <fmmp-i18n :text="prop.label" />
                            </b>
                            :
                            {{ transformToMissedValue(filter && filter.value) }}
                        </li>
                    </ul>
                    <button
                        v-if="filter.id === selectedRow"
                        class="button-main select selected"
                        disabled
                    >
                        <i class="fa fa-check"></i> <fmmp-i18n text="Selected" />
                    </button>
                    <button
                        v-else
                        class="button-main select"
                        @click="
                            () => {
                                handleRadioBtnClick(i);
                                showMobileSelector = false;
                            }
                        "
                    >
                        <fmmp-i18n text="Select version" />
                    </button>
                </div>
            </fmmp-modal>
        </div>
    </div>
</template>

<script>
import {
    transformToMissedValue,
    getTranslation,
} from "../../../../common/partFinderCorporate.helpers";
import { getQsParams } from "../helpers";

const ENGINE_VERSION_PROPS = [{ label: "engine versions", apiField: "value" }];
const ENGINE_FILTERS_PARAM = "engineFilter";

export default Vue.component("driv-engine-search-filtering-table", {
    props: {
        tableFilters: {
            type: Array,
            default: () => [],
        },
        updateResults: {
            type: Function,
            default: () => {},
        },
        enginePerformance: {
            type: Boolean,
            default: () => false,
        },
        engineHeavy: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            engineVersionProps: ENGINE_VERSION_PROPS,
            selectedRow: {},
            showMobileSelector: false,
        };
    },
    methods: {
        transformToMissedValue,
        handleRadioBtnClick(index) {
            this.selectedRow = this.tableFilters.find((filter, i) => i === index)?.id;
            const urlPath = window.location.pathname;
            const queryString = Qs.stringify(
                {
                    ...getQsParams(),
                    [ENGINE_FILTERS_PARAM]: this.selectedRow,
                    page: 1,
                },
                { encodeValuesOnly: true },
            );

            const newUrl = `${urlPath}?${queryString}`;
            const updateResults = this.updateResults;

            Vue.Global.Analytics.trackEvent(
                "engine-part-results",
                "click-engine-filter",
                this.selectedRow,
                null,
                {
                    callback: function () {
                        window.history.pushState({ urlPath: newUrl }, document.title, newUrl);
                        updateResults();
                    },
                },
            );
        },
        getTableHeaderData() {
            const {
                engineMfr,
                engineBase,
                engineUsage,
                performanceMfr,
                performanceBase,
                vehicle,
                vehicleGroupIds,
                heavyMfr,
                heavyBase,
            } = getQsParams();

            const enginesText = getTranslation("Engines");

            // Light Duty: mfr + base + usage + "Engines" (drivcat order)
            // Heavy Duty: mfr + base + searchType + "Engines"

            if (this.enginePerformance) {
                return `${performanceMfr?.label || ""} ${vehicle.label} ${
                    performanceBase?.label || ""
                } ${vehicleGroupIds.label} ${enginesText}`;
            } else if (this.engineHeavy) {
                return `${heavyMfr.label} ${heavyBase.label} ${vehicleGroupIds.label} ${enginesText}`;
            }

            return `${engineMfr.label} ${engineBase.label} ${engineUsage.label} ${enginesText}`;
        },
    },
    created() {
        const paramsObj = Qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (!paramsObj[ENGINE_FILTERS_PARAM]) {
            this.selectedRow = this.tableFilters[0];
            this.handleRadioBtnClick(0);
        } else {
            this.selectedRow = paramsObj[ENGINE_FILTERS_PARAM];
        }
    },
});
</script>
