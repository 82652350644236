var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.componentKey, staticClass: "driv-corp-grid-view-container" },
    _vm._l(_vm.updatedProducts, function(productsGroup, index) {
      return _c(
        "div",
        {
          key: productsGroup.brand + productsGroup.imageNotExist,
          staticClass: "driv-corp-grid-view-group"
        },
        [
          _c("div", { staticClass: "product-logo" }, [
            !productsGroup.imageNotExist
              ? _c("img", {
                  attrs: {
                    src: _vm.getBrandImgUrl(productsGroup),
                    alt: productsGroup.brand
                  },
                  on: {
                    error: function($event) {
                      return _vm.onErrorLoad(index)
                    }
                  }
                })
              : _c("span", [_vm._v(_vm._s(productsGroup.brand))])
          ]),
          _vm._v(" "),
          _c(
            "table",
            [
              _vm._l(productsGroup.part_type_list, function(productType, i) {
                return [
                  _c(
                    "tr",
                    {
                      key: productType.part_type_value,
                      staticClass: "product-type-group"
                    },
                    [
                      _c(
                        "td",
                        { attrs: { colspan: _vm.filteredTableColumns.length } },
                        [
                          _c("fmmp-i18n", {
                            attrs: { text: productType.part_type_value }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tr",
                    {
                      key: productType.part_type_value + "-" + i,
                      staticClass: "product-columns"
                    },
                    [
                      _vm._l(_vm.filteredTableColumns, function(column) {
                        return [
                          _c(
                            "td",
                            {
                              key: column.className,
                              class: "product-" + column.className
                            },
                            [
                              column.label
                                ? _c("fmmp-i18n", {
                                    attrs: { text: column.label }
                                  })
                                : column.className === "image"
                                ? _c("i", { staticClass: "fas fa-image" })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm._l(productType.parts_list, function(productInfo, i) {
                    return [
                      _vm.isGurusProductInsights
                        ? _c("driv-gpi-grid-view-item", {
                            key: _vm.getUniqueKey(productInfo, i),
                            attrs: {
                              product: productInfo,
                              detailsPath: _vm.detailsPath,
                              columns: _vm.filteredTableColumns,
                              toggleQuickDetails: function() {
                                return _vm.toggleQuickDetails(
                                  _vm.getUniqueKey(productInfo, i)
                                )
                              },
                              isDetailsOpen:
                                _vm.showQuickDetails ===
                                _vm.getUniqueKey(productInfo, i),
                              togglePartSelection: _vm.togglePartSelection,
                              isPartSelected: _vm.isPartSelected,
                              isReadOnly: _vm.isReadOnly
                            }
                          })
                        : _c("driv-corp-grid-view-item", {
                            key: _vm.getUniqueKey(productInfo, i),
                            attrs: {
                              product: productInfo,
                              detailsPath: _vm.detailsPath,
                              wtbPath: _vm.wtbPath,
                              columns: _vm.filteredTableColumns,
                              toggleQuickDetails: function() {
                                return _vm.toggleQuickDetails(
                                  _vm.getUniqueKey(productInfo, i)
                                )
                              },
                              isDetailsOpen:
                                _vm.showQuickDetails ===
                                _vm.getUniqueKey(productInfo, i),
                              hideWtbBtns: _vm.hideWtbBtns
                            }
                          }),
                      _vm._v(" "),
                      _vm.showQuickDetails === _vm.getUniqueKey(productInfo, i)
                        ? [
                            _c("driv-quick-details-grid-view", {
                              key: productInfo.item_id + "-" + i + "-detail",
                              attrs: {
                                product: productInfo,
                                toggleQuickDetails: _vm.toggleQuickDetails,
                                detailsPath: _vm.detailsPath,
                                showQuickDetails: _vm.getUniqueKey(
                                  productInfo,
                                  i
                                )
                              }
                            })
                          ]
                        : _vm._e()
                    ]
                  })
                ]
              })
            ],
            2
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }