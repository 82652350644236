var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "driv-corp-list-view" },
    [
      _vm.isGurusProductInsights
        ? _vm._l(_vm.products, function(product, i) {
            return _c("driv-gpi-list-view-item", {
              key: i + product.part_number,
              attrs: {
                product: product,
                detailsPath: _vm.detailsPath,
                togglePartSelection: _vm.togglePartSelection,
                isPartSelected: _vm.isPartSelected,
                isReadOnly: _vm.isReadOnly
              }
            })
          })
        : _vm._l(_vm.products, function(product, i) {
            return _c("driv-corp-list-view-item", {
              key: i + product.part_number,
              attrs: {
                wtbPath: _vm.wtbPath,
                product: product,
                detailsPath: _vm.detailsPath,
                hideWtbBtns: _vm.hideWtbBtns
              }
            })
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }