var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.error
        ? _c("driv-empty-part-results-page")
        : _c("div", { staticClass: "driv-part-results-content" }, [
            _c("div", { staticClass: "part-results-head" }, [
              _c(
                "div",
                { key: _vm.componentKey, staticClass: "product-logo" },
                [
                  _c("fmmp-brandlogo", {
                    attrs: { option: _vm.brandLogo, isResult: true },
                    on: { onErrorImageLoad: _vm.onErrorImageLoad }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "popup-buttons" },
                [
                  _vm.isChampionBrand
                    ? _c("driv-info-modal", {
                        attrs: { infoModalText: _vm.infoModal }
                      })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "clear-filters-block",
                  _vm.isGurusProductInsights && _vm.numericFieldsNames.length
                    ? "absolute-position"
                    : ""
                ]
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "button-main reset-filters",
                    attrs: { disabled: _vm.isClearFiltersDisabled },
                    on: { click: _vm.resetFilters }
                  },
                  [_c("fmmp-i18n", { attrs: { text: "Clear Filters" } })],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "results-summary" },
              [
                _c(
                  "span",
                  { staticClass: "results-quantity" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.pagination.total) +
                        "\n                "
                    ),
                    _c("fmmp-i18n", { attrs: { text: "Part Results" } })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isPaginationShown
                  ? _c("driv-corp-list-pagination", {
                      attrs: {
                        savedPage: _vm.pagination.page,
                        totalPages: _vm.pagesQty,
                        onPageChange: _vm.handlePageChange
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "specification-table" },
              [
                _c("fmmp-table", {
                  attrs: {
                    columnConfig: _vm.columnConfig,
                    tableData: _vm.tableData,
                    gridOptions: _vm.tableProps
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.isPaginationShown
              ? _c(
                  "div",
                  { staticClass: "results-summary footer" },
                  [
                    _c(
                      "div",
                      { staticClass: "results-quantity" },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.pagination.total) +
                            "\n                "
                        ),
                        _c("fmmp-i18n", { attrs: { text: "Part Results" } })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("driv-corp-list-pagination", {
                      attrs: {
                        savedPage: _vm.pagination.page,
                        totalPages: _vm.pagesQty,
                        onPageChange: function(v) {
                          return _vm.handlePageChange(v, true)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }