var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "driv-info-modal" },
    [
      _c(
        "span",
        { on: { click: _vm.toggleInfoModal } },
        [_c("fmmp-i18n", { attrs: { text: "show plug selection steps" } })],
        1
      ),
      _vm._v(" "),
      _vm.isModalVisible
        ? _c(
            "fmmp-modal",
            {
              attrs: { rootClass: "driv-result-specification" },
              on: { close: _vm.toggleInfoModal }
            },
            [
              _c("template", { slot: "title" }),
              _vm._v(" "),
              _c("div", { staticClass: "modal-header" }),
              _vm._v(" "),
              _c("div", {
                staticClass: "modal-text",
                domProps: { innerHTML: _vm._s(_vm.infoModalText) }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button-main info",
                  on: { click: _vm.toggleInfoModal }
                },
                [_c("fmmp-i18n", { attrs: { text: "close" } })],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }