var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "driv-corp-list-view-item-container" }, [
    _c("div", { staticClass: "driv-corp-list-view-item-image list-desktop" }, [
      _c("img", { attrs: { src: _vm.imageSrc, alt: _vm.product.title } })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "driv-corp-list-view-item-content" }, [
      _c(
        "div",
        { staticClass: "driv-corp-list-view-item-header" },
        [
          _c("driv-corp-part-name-section", {
            attrs: {
              product: _vm.product,
              partName: _vm.product.product,
              handleGoToDetails: function() {
                return _vm.handleGoToDetails(
                  _vm.product.part_number,
                  _vm.product.brand,
                  _vm.detailsPath
                )
              }
            }
          }),
          _vm._v(" "),
          _vm.hasDiagram
            ? _c(
                "button",
                {
                  staticClass: "interactive-diagrams-btn",
                  on: {
                    click: function($event) {
                      return _vm.openDiagramPage(_vm.product)
                    }
                  }
                },
                [_c("fmmp-i18n", { attrs: { text: "Interactive Diagrams" } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "driv-corp-list-view-item-image" }, [
            _c("img", { attrs: { src: _vm.imageSrc, alt: _vm.product.title } })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "action-buttons" },
            [
              !_vm.hideWtbBtns
                ? _c(
                    "a",
                    {
                      staticClass:
                        "button-main driv-corp-list-view-item-wtb-button",
                      on: {
                        click: function() {
                          return _vm.handleWtbClick({
                            partNumber: _vm.product.part_number,
                            brand: _vm.product.wtb_brand_name
                          })
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.getTranslation("where to buy")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.hideWtbBtns &&
              _vm.product.online_store_list &&
              _vm.product.online_store_list.length
                ? _c("driv-dropdown-button", {
                    attrs: {
                      buttonClasses: "driv-corp-list-view-item-wtb-button",
                      text: _vm.getTranslation("Buy now"),
                      options: _vm.mapPartOnlineStoreToOptions(
                        _vm.product.online_store_list,
                        _vm.product.part_number
                      )
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "driv-corp-list-view-item-details" }, [
        _c(
          "div",
          { staticClass: "driv-corp-list-view-item-info" },
          [
            _c("h4", { staticClass: "driv-corp-list-view-item-info-header" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.getTranslation("Application criteria")) +
                  "\n                "
              )
            ]),
            _vm._v(" "),
            _vm.isApplicationCriteriaDataShown
              ? _vm._l(_vm.appCriteriaItems, function(item) {
                  return _c(
                    "div",
                    { key: item.label, staticClass: "details-item" },
                    [
                      _c("span", { staticClass: "details-item-label" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.getTranslation(item.label)) +
                            ":\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "details-item-value" }, [
                        _vm._v(_vm._s(item.value))
                      ])
                    ]
                  )
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.vcdbAttributes && _vm.isApplicationCriteriaDataShown
              ? _vm._l(_vm.vcdbAttributes, function(item) {
                  return _c(
                    "div",
                    { key: item.property, staticClass: "details-item" },
                    [
                      _c("span", { staticClass: "details-item-label" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.getTranslation(item.property)) +
                            ":\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "details-item-value" }, [
                        _vm._v(_vm._s(item.value))
                      ])
                    ]
                  )
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.product.qty
              ? _c("div", { staticClass: "details-item" }, [
                  _c("span", { staticClass: "details-item-label" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.getTranslation(_vm.applicationQuantity.property)
                        ) +
                        ":\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "details-item-value" }, [
                    _vm._v(_vm._s(_vm.applicationQuantity.value))
                  ])
                ])
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _vm.isAdditionalInfoBlockShown
          ? _c(
              "div",
              { staticClass: "driv-corp-list-view-item-additional-info" },
              [
                _c(
                  "h4",
                  { staticClass: "driv-corp-list-view-item-info-header" },
                  [_vm._v(_vm._s(_vm.additionalInfoTitle) + ":")]
                ),
                _vm._v(" "),
                !_vm.performanceEngineSearchType
                  ? _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.product.additional_fit_criteria) +
                          "\n                "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.product.notes && _vm.performanceEngineSearchType
                  ? _vm._l(_vm.product.notes, function(note) {
                      return _c(
                        "div",
                        { key: note.note_id, staticClass: "details-item" },
                        [_c("span", [_vm._v(_vm._s(note.note))])]
                      )
                    })
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }