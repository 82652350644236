var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clickable-part-number" }, [
    _c("div", { staticClass: "clickable-part-number__block" }, [
      _c("a", { on: { click: _vm.handleGoToDetails } }, [
        _vm._v(_vm._s(_vm.params.value))
      ])
    ]),
    _vm._v(" "),
    _vm.isShowImgs
      ? _c(
          "div",
          {
            staticClass:
              "clickable-part-number__thumbnail-img-wrapper clickable-part-number__block"
          },
          [
            _c("img", {
              staticClass: "clickable-part-number__img-for-mobile-view",
              attrs: {
                src: _vm.thumbnailImgSrc,
                alt: _vm.params.value + " img"
              }
            }),
            _vm._v(" "),
            _c(
              "driv-popover",
              {
                attrs: {
                  offset: "0, 20px",
                  placement: "right",
                  popperClass: "primary-img-popper"
                }
              },
              [
                _c("template", { slot: "popper-content" }, [
                  _c("div", { staticClass: "popper__primary-img-wrapper" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.primaryImgSrc,
                        alt: _vm.params.value + " img"
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("template", { slot: "popper-trigger" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.thumbnailImgSrc,
                      alt: _vm.params.value + " img"
                    }
                  })
                ])
              ],
              2
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }