<template>
    <div class="ujoint-common-page">
        <div class="ujoint-common-page__image">
            <img :src="imgSrc" alt="" />
        </div>
        <div class="ujoint-common-page__info info">
            <div class="info__header">
                <fmmp-brandlogo
                    :option="brandLogo"
                    :isResult="true"
                    containerClass="ujoint-pages-brand-logo"
                    @onErrorImageLoad="onErrorImageLoad"
                ></fmmp-brandlogo>
                <span class="title">{{ title }}</span>
            </div>
            <div class="ujoint-common-page__image mobile">
                <img :src="imgSrc" alt="" />
            </div>
            <driv-tab :name="instructionsTitle" :order="0">
                <span> {{ instructionsTitle }} </span>
                <div v-html="instructionsText"></div>
            </driv-tab>
        </div>
        <driv-tabs v-if="!noTabs" class="ujoint-common-page__tabs">
            <driv-tab
                :name="getTranslation('Inches')"
                :order="0"
                :selected="true"
                :tabsOnMobile="true"
            >
                <slot name="inches-tab"></slot>
            </driv-tab>
            <driv-tab :name="getTranslation('Millimeters')" :order="1" :tabsOnMobile="true">
                <slot name="millimeters-tab"></slot>
            </driv-tab>
        </driv-tabs>
    </div>
</template>

<script>
import { getTranslation } from "../../../../common/partFinderCorporate.helpers";
import { getQsParams } from "../helpers";

export default Vue.component("driv-ujoint-common-page-layout", {
    props: {
        title: {
            type: String,
            default: "",
        },
        instructionsText: {
            type: String,
            default: "",
        },
        imgSrc: {
            type: String,
            default: "",
        },
        noTabs: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        brandLogo() {
            const { brands } = getQsParams();
            return { ...brands, isUrlValid: true } || {};
        },
        instructionsTitle() {
            return getTranslation("Instructions");
        },
    },
    methods: {
        getTranslation,
        onErrorImageLoad() {
            this.brandLogo.isUrlValid = false;
            // force re-render
            this.componentKey += 1;
        },
    },
});
</script>
