var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "driv-corp-torque-results" },
    [
      _vm.isTorqueDetails
        ? _c("driv-corp-torque-details", {
            attrs: {
              hubPage1stLogo: _vm.hubPage1stLogo,
              hubPage2ndLogo: _vm.hubPage2ndLogo,
              cylinderPage1stLogo: _vm.cylinderPage1stLogo,
              cylinderPage2ndLogo: _vm.cylinderPage2ndLogo,
              torqueSpecRemark: _vm.torqueSpecRemark
            }
          })
        : _c("div", { staticClass: "driv-corp-torque-results-page" }, [
            _c("span", { staticClass: "title mobile" }, [
              _vm._v(_vm._s(_vm.pageTitle))
            ]),
            _vm._v(" "),
            _vm.viewPdfRedirectLink && _vm.isCylinderHeadPage
              ? _c("div", { staticClass: "pdf-container" }, [
                  _vm.pdfImageSrc
                    ? _c("img", {
                        attrs: {
                          src: _vm.pdfImageSrc,
                          alt: "Torque PDF document"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      key: _vm.pdfUrl,
                      attrs: {
                        href: _vm.pdfUrl,
                        target: "_blank",
                        rel: "noreferrer noopener",
                        type: "application/pdf"
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.pdfRedirectLinkText) +
                          "\n            "
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "torque-results-info",
                  {
                    "hub-wheel-bearing":
                      !_vm.isCylinderHeadPage || !_vm.viewPdfRedirectLink
                  }
                ]
              },
              [
                _c(
                  "div",
                  { staticClass: "fields-container" },
                  [
                    _c("span", { staticClass: "title desktop" }, [
                      _vm._v(_vm._s(_vm.pageTitle))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "filters" },
                      [_c("fmmp-i18n", { attrs: { text: "Filters" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("driv-refine-torque-search", {
                      attrs: { torqueYears: _vm.torqueYears }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("driv-corp-torque-results-table")
              ],
              1
            )
          ]),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "additional-info",
            { withAdditionalLink: _vm.withAdditionalLink }
          ]
        },
        [
          _vm.withAdditionalLink
            ? _c(
                "a",
                {
                  staticClass: "additional-link",
                  attrs: {
                    href: _vm.calculatedTorqueLinkUrl,
                    target: "_blank",
                    rel: "noreferrer noopener"
                  }
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.torqueLinkText) + "\n        "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "provider-info" }, [
            _vm.torqueApiProviderLogo
              ? _c("div", { staticClass: "image_container" }, [
                  _c("img", { attrs: { src: _vm.torqueApiProviderLogo } })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.torqueApiProviderText
              ? _c("span", [_vm._v(_vm._s(_vm.torqueApiProviderText))])
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }