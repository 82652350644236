var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "driv-gpi-item-part-name" }, [
    _c("div", { staticClass: "driv-gpi-item-part-name-title" }, [
      _c("input", {
        staticClass: "select-part",
        attrs: {
          type: "checkbox",
          id: _vm.customId,
          name: _vm.attributeNameValue,
          disabled: _vm.isReadOnly
        },
        domProps: { checked: _vm.isPartSelected(_vm.customId) },
        on: {
          click: function($event) {
            return _vm.togglePartSelection($event)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: { rel: "noopener noreferrer", target: "_blank" },
          on: { click: _vm.goToPartDetailsPage }
        },
        [_vm._v("\n            " + _vm._s(_vm.partName) + "\n        ")]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "driv-gpi-item-part-name-subheading" }, [
      _c(
        "div",
        { staticClass: "driv-gpi-item-part-name-part-number" },
        [
          _c("fmmp-i18n", { attrs: { text: "Part No" } }),
          _vm._v(":\n            "),
          _c(
            "a",
            {
              attrs: { rel: "noopener noreferrer", target: "_blank" },
              on: { click: _vm.goToPartDetailsPage }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.product.part_number) +
                  "\n            "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "driv-gpi-item-part-name-brand" },
        [
          _c("fmmp-i18n", { attrs: { text: "Brand" } }),
          _vm._v(":\n            "),
          _c("span", [_vm._v(_vm._s(_vm.product.brand_name))])
        ],
        1
      ),
      _vm._v(" "),
      _vm.product.sub_brand_name
        ? _c(
            "div",
            { staticClass: "driv-gpi-item-part-name-subbrand" },
            [
              _c("fmmp-i18n", { attrs: { text: "Subbrand" } }),
              _vm._v(":\n            "),
              _c("span", [_vm._v(_vm._s(_vm.product.sub_brand_name))])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "driv-gpi-item-part-name-notes",
            _vm.hasPartNotes && "has-part-notes"
          ]
        },
        [_c("fmmp-i18n", { attrs: { text: "Part Notes" } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }