<template>
    <div class="driv-info-modal">
        <span @click="toggleInfoModal">
            <fmmp-i18n text="show plug selection steps" />
        </span>
        <fmmp-modal
            v-if="isModalVisible"
            rootClass="driv-result-specification"
            @close="toggleInfoModal"
        >
            <template slot="title"></template>
            <div class="modal-header" />
            <div v-html="infoModalText" class="modal-text"></div>
            <button class="button-main info" @click="toggleInfoModal">
                <fmmp-i18n text="close" />
            </button>
            <div class="modal-footer" />
        </fmmp-modal>
    </div>
</template>

<script>
import { createOverlay, removeOverlay } from "../../../../common/partFinderCorporate.helpers";
import { coverFuncWithAnalytics } from "./specification.helpers";

export default Vue.component("driv-info-modal", {
    data() {
        return {
            isModalVisible: false,
        };
    },
    props: {
        infoModalText: {
            type: String,
            default: "",
        },
    },
    methods: {
        toggleInfoModal() {
            coverFuncWithAnalytics({
                func: () => {
                    this.isModalVisible = !this.isModalVisible;
                },
                eventAction: "click-show-plug-selection-steps",
            });
        },
    },
    watch: {
        isModalVisible(isVisible) {
            isVisible ? createOverlay(this.toggleInfoModal) : removeOverlay();
        },
    },
});
</script>
