<template>
    <div class="driv-corp-torque-results">
        <driv-corp-torque-details
            v-if="isTorqueDetails"
            :hubPage1stLogo="hubPage1stLogo"
            :hubPage2ndLogo="hubPage2ndLogo"
            :cylinderPage1stLogo="cylinderPage1stLogo"
            :cylinderPage2ndLogo="cylinderPage2ndLogo"
            :torqueSpecRemark="torqueSpecRemark"
        ></driv-corp-torque-details>

        <div class="driv-corp-torque-results-page" v-else>
            <span class="title mobile">{{ pageTitle }}</span>
            <div class="pdf-container" v-if="viewPdfRedirectLink && isCylinderHeadPage">
                <img v-if="pdfImageSrc" :src="pdfImageSrc" alt="Torque PDF document" />
                <a
                    :key="pdfUrl"
                    :href="pdfUrl"
                    target="_blank"
                    rel="noreferrer noopener"
                    type="application/pdf"
                >
                    {{ pdfRedirectLinkText }}
                </a>
            </div>
            <div
                :class="[
                    'torque-results-info',
                    { 'hub-wheel-bearing': !isCylinderHeadPage || !viewPdfRedirectLink },
                ]"
            >
                <div class="fields-container">
                    <span class="title desktop">{{ pageTitle }}</span>
                    <div class="filters">
                        <fmmp-i18n text="Filters" />
                    </div>
                    <driv-refine-torque-search
                        :torqueYears="torqueYears"
                    ></driv-refine-torque-search>
                </div>
                <driv-corp-torque-results-table></driv-corp-torque-results-table>
            </div>
        </div>
        <div :class="['additional-info', { withAdditionalLink }]">
            <a
                v-if="withAdditionalLink"
                class="additional-link"
                :href="calculatedTorqueLinkUrl"
                target="_blank"
                rel="noreferrer noopener"
            >
                {{ torqueLinkText }}
            </a>
            <div class="provider-info">
                <div class="image_container" v-if="torqueApiProviderLogo">
                    <img :src="torqueApiProviderLogo" />
                </div>
                <span v-if="torqueApiProviderText">{{ torqueApiProviderText }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { getQsParams } from "../helpers";
import {
    getCorrectUrlForLinks,
    getTranslation,
} from "../../../../common/partFinderCorporate.helpers";
import { DEFAULT_TORQUE_PDF_LINK_NAME } from "../constants";

const { LOOK_UP_IDS } = Vue.PartFinder;

export default Vue.component("driv-corp-torque-results", {
    data() {
        return {
            pdfUrl: "",
        };
    },
    props: {
        torqueYears: {
            type: Array,
            default: () => [],
        },
        pdfImageSrc: {
            type: String,
            default: "",
        },
        viewPdfRedirectLink: {
            type: String,
            default: "",
        },
        pdfRedirectLinkText: {
            type: String,
            default: DEFAULT_TORQUE_PDF_LINK_NAME,
        },
        hubPage1stLogo: {
            type: String,
            default: "",
        },
        hubPage2ndLogo: {
            type: String,
            default: "",
        },
        cylinderPage1stLogo: {
            type: String,
            default: "",
        },
        cylinderPage2ndLogo: {
            type: String,
            default: "",
        },
        torqueSpecRemark: {
            type: String,
            default: "",
        },
        torqueApiProviderLogo: {
            type: String,
            default: "",
        },
        torqueApiProviderText: {
            type: String,
            default: "",
        },
        torqueLinkText: {
            type: String,
            default: "",
        },
        torqueLinkPath: {
            type: String,
            default: "",
        },
    },
    computed: {
        pageTitle() {
            const { contentSilos } = getQsParams();
            return `${contentSilos.label}  ${getTranslation("Torque Specification Lookup")}`;
        },
        isCylinderHeadPage() {
            const { contentSilos } = getQsParams();
            return contentSilos.value === LOOK_UP_IDS.TORQUE_CYLINDER_HEAD_CONTENT;
        },
        isTorqueDetails() {
            const { baseId } = getQsParams();
            return !!baseId;
        },
        withAdditionalLink() {
            return this.torqueLinkPath && this.torqueLinkText;
        },
        calculatedTorqueLinkUrl() {
            if (!this.torqueLinkPath) return;
            return getCorrectUrlForLinks(this.torqueLinkPath);
        },
    },
    methods: {
        getTranslation,
    },
    created() {
        if (this.viewPdfRedirectLink) {
            Vue.CatalogApi.CatalogHttpService.getPdfUrl(this.viewPdfRedirectLink).then((pdfUrl) => {
                this.pdfUrl = pdfUrl;
            });
        }
    },
    beforeDestroy() {
        if (this.pdfUrl) {
            window.URL.revokeObjectURL(this.pdfUrl);
        }
    },
});
</script>
