var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "torque-details-page" }, [
    _c("div", { staticClass: "back-to-results-link" }, [
      _c("a", { on: { click: _vm.deleteBaseAndDocIdsFromUrl } }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.getTranslation("Back To Search Results")) +
            "\n        "
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "torque-details" }, [
      _vm.isImageShown
        ? _c("div", { staticClass: "torque-details__image" }, [
            _c("img", {
              attrs: { src: _vm.imgSrc, alt: "Torque Specification image" }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: ["torque-details__info", { "no-image": !_vm.isImageShown }] },
        [
          _c(
            "div",
            { staticClass: "info-header" },
            [
              _vm.isCylinderHead
                ? _c("div", { staticClass: "logos" }, [
                    _vm.cylinderPage1stLogo
                      ? _c("div", [
                          _c("img", {
                            attrs: {
                              src: _vm.cylinderPage1stLogo,
                              alt: _vm.cylinderPage1stLogo
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.cylinderPage2ndLogo
                      ? _c("div", [
                          _c("img", {
                            attrs: {
                              src: _vm.cylinderPage2ndLogo,
                              alt: _vm.cylinderPage2ndLogo
                            }
                          })
                        ])
                      : _vm._e()
                  ])
                : _c("div", { staticClass: "logos" }, [
                    _vm.hubPage1stLogo
                      ? _c("div", [
                          _c("img", {
                            attrs: {
                              src: _vm.hubPage1stLogo,
                              alt: _vm.hubPage1stLogo
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hubPage2ndLogo
                      ? _c("div", [
                          _c("img", {
                            attrs: {
                              src: _vm.hubPage2ndLogo,
                              alt: _vm.hubPage2ndLogo
                            }
                          })
                        ])
                      : _vm._e()
                  ]),
              _vm._v(" "),
              _c("fmmp-i18n", { attrs: { text: "Torque Specs" } })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "applied-filters" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.getTranslation("Applied Filters")) + ":")
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", [_vm._v(_vm._s(_vm.getTranslation("Year")) + ":")]),
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.ymmFromUrl.year) +
                  "\n                "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", [_vm._v(_vm._s(_vm.getTranslation("Make")) + ":")]),
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.ymmFromUrl.make) +
                  "\n                "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", [_vm._v(_vm._s(_vm.getTranslation("Model")) + ":")]),
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.ymmFromUrl.model) +
                  "\n                "
              )
            ]),
            _vm._v(" "),
            _vm.isCylinderHead
              ? _c("p", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.getTranslation("Engine")) + ":")
                  ]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.ymmFromUrl.engine) +
                      "\n                "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.error
            ? _c(
                "div",
                {
                  class: [
                    "notes",
                    { bordered: _vm.isCylinderHead && !_vm.error }
                  ]
                },
                [
                  _vm.isCylinderHead
                    ? _c(
                        "div",
                        [
                          _c("fmmp-i18n", {
                            staticClass: "title",
                            attrs: { text: "Torque Specifications" }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "steps" },
                            [
                              _vm._l(_vm.procNotes, function(note, i) {
                                return [
                                  _c(
                                    "p",
                                    {
                                      key: note + i,
                                      staticClass: "proc-notes"
                                    },
                                    [_vm._v(_vm._s(note))]
                                  )
                                ]
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "remark" }, [
                            _vm._v(_vm._s(_vm.torqueSpecRemark))
                          ])
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _vm._l(_vm.notesArrayForHubAndWheel, function(
                            note,
                            i
                          ) {
                            return [
                              _c("p", { key: note + i }, [_vm._v(_vm._s(note))])
                            ]
                          })
                        ],
                        2
                      )
                ]
              )
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }