<template>
    <div class="selected-diameter-info-page">
        <driv-ujoint-common-page-layout
            :title="title"
            :instructionsText="instructions"
            :imgSrc="imgSrc"
            :noTabs="isTabsHidden"
        >
            <template v-slot:inches-tab>
                <div class="specification-table">
                    <fmmp-table
                        :gridOptions="inTableProps"
                        :tableData="inTableData"
                        :columnConfig="inColConfig"
                    ></fmmp-table>
                </div>
            </template>
            <template v-slot:millimeters-tab>
                <div class="specification-table">
                    <fmmp-table
                        :gridOptions="mmTableProps"
                        :tableData="mmTableData"
                        :columnConfig="mmColConfig"
                    ></fmmp-table>
                </div>
            </template>
        </driv-ujoint-common-page-layout>
    </div>
</template>

<script>
import { SPECIFICATIONS_QS_OPTIONS, SPECIFICATION_PROPERTIES, UOM_VALUES } from "../constants";
import { getDoubleEncodedHashParams, getSelectedDiameterValue } from "../helpers";
import ClickablePartNumber from "../specification-results/ClickablePartNumber.vue";
import {
    prepareColumns,
    prepareTableData,
    setColAndDataToTable,
    setInitColsWidth,
} from "../specification-results/specification.helpers";

const TABLE_PROPS = {
    suppressScrollOnNewData: true,
    suppressRowHoverHighlight: true,
    suppressCellSelection: true,
    suppressContextMenu: true,
    suppressMovableColumns: true,
    suppressRowDrag: true,
    defaultColDef: {
        autoHeight: true,
        wrapText: true,
    },
    frameworkComponents: {
        partNumberCellRenderer: ClickablePartNumber,
    },
};

export default Vue.component("driv-corp-u-joint-selected-diameter-info-page", {
    data() {
        return {
            imgSrc: "",
            title: "",
            instructions: null,
            inTableProps: { ...TABLE_PROPS, onGridReady: this.onInGridReady },
            mmTableProps: { ...TABLE_PROPS, onGridReady: this.onMmGridReady },
            inGridApi: null,
            inGridColumnApi: null,
            inTableData: [],
            inColConfig: [],
            mmTableData: [],
            mmColConfig: [],
            mmGridApi: null,
            mmGridColumnApi: null,
            sortedMasterList: null,
        };
    },
    props: {
        specifications: {
            type: Object,
            default: () => ({}),
        },
        detailsPath: {
            type: String,
            default: "",
        },
    },
    computed: {
        isTabsHidden() {
            if (!this.specifications) return false;
            return !this.specifications[SPECIFICATION_PROPERTIES.MASTER_LIST_PROPERTY]?.length;
        },
    },
    methods: {
        setColsAndData({ gridApi, gridColumnApi, specifications }) {
            setColAndDataToTable({
                gridApi,
                specifications,
                prepareColumns: this.prepareColumns,
                prepareTableData: this.prepareTableData,
            });

            setInitColsWidth({
                gridApi,
                gridColumnApi,
            });
        },
        onInGridReady(params) {
            this.inGridApi = params.api;
            this.inGridColumnApi = params.columnApi;

            if (this.sortedMasterList) {
                this.setColsAndData({
                    gridApi: this.inGridApi,
                    gridColumnApi: this.inGridColumnApi,
                    uom: UOM_VALUES.IN,
                    specifications: {
                        ...this.specifications,
                        [SPECIFICATION_PROPERTIES.MASTER_LIST_PROPERTY]:
                            this.sortedMasterList[UOM_VALUES.IN],
                    },
                });
            }
        },
        onMmGridReady(params) {
            this.mmGridApi = params.api;
            this.mmGridColumnApi = params.columnApi;

            if (this.sortedMasterList) {
                this.setColsAndData({
                    gridApi: this.mmGridApi,
                    gridColumnApi: this.mmGridColumnApi,
                    uom: UOM_VALUES.MM,
                    specifications: {
                        ...this.specifications,
                        [SPECIFICATION_PROPERTIES.MASTER_LIST_PROPERTY]:
                            this.sortedMasterList[UOM_VALUES.MM],
                    },
                });
            }
        },
        getColId(name, uom) {
            return uom ? `${name}_${uom}` : name;
        },
        prepareColumns(masterList) {
            return prepareColumns(masterList, (item) => ({
                field: this.getColId(item.name, item.uom),
                headerName: item.name,
            }));
        },
        prepareTableData(data) {
            return prepareTableData(
                data,
                (attr) => ({
                    [this.getColId(attr.attribute, attr.attribute_uom)]: attr.attribute_value,
                }),
                this.detailsPath,
            );
        },
        getSpecificationsWithFilteredMasterDataByUom(uom, newSpecifications) {
            const specifications = newSpecifications || this.specifications;

            if (specifications[SPECIFICATION_PROPERTIES.MASTER_LIST_PROPERTY])
                return {
                    ...specifications,
                    [SPECIFICATION_PROPERTIES.MASTER_LIST_PROPERTY]: specifications[
                        SPECIFICATION_PROPERTIES.MASTER_LIST_PROPERTY
                    ].filter((item) => !item.uom || item.uom === uom),
                };

            return specifications;
        },
        getFilteredMasterList(masterList) {
            return masterList.reduce(
                (acc, item) => {
                    if (!item.uom) {
                        acc[UOM_VALUES.IN].push(item);
                        acc[UOM_VALUES.MM].push(item);
                        return acc;
                    }

                    acc[item.uom].push(item);
                    return acc;
                },
                { [UOM_VALUES.IN]: [], [UOM_VALUES.MM]: [] },
            );
        },
        setSpecificationsData(newSpecifications) {
            const uJointAttrs = (newSpecifications[SPECIFICATION_PROPERTIES.UJOINT_ATTRIBUTES] ||
                [])[0];

            if (uJointAttrs) {
                const hashFilters = getDoubleEncodedHashParams(SPECIFICATIONS_QS_OPTIONS);
                const attributeFilters = (hashFilters || {})[
                    SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS
                ];

                this.title = getSelectedDiameterValue(attributeFilters);
                this.instructions = uJointAttrs.instruction;
                this.imgSrc = uJointAttrs.attribute_image_url;
            }

            const filteredMasterList =
                this.getFilteredMasterList(
                    newSpecifications[SPECIFICATION_PROPERTIES.MASTER_LIST_PROPERTY] || [],
                ) || [];

            this.sortedMasterList = filteredMasterList;

            this.setColsAndData({
                gridApi: this.mmGridApi,
                gridColumnApi: this.mmGridColumnApi,
                uom: UOM_VALUES.MM,
                specifications: {
                    ...newSpecifications,
                    [SPECIFICATION_PROPERTIES.MASTER_LIST_PROPERTY]:
                        filteredMasterList[UOM_VALUES.MM],
                },
            });

            this.setColsAndData({
                gridApi: this.inGridApi,
                gridColumnApi: this.inGridColumnApi,
                uom: UOM_VALUES.IN,
                specifications: {
                    ...newSpecifications,
                    [SPECIFICATION_PROPERTIES.MASTER_LIST_PROPERTY]:
                        filteredMasterList[UOM_VALUES.IN],
                },
            });
        },
    },
    watch: {
        specifications(newSpecifications) {
            this.setSpecificationsData(newSpecifications);
        },
    },
    created() {
        if (this.specifications) this.setSpecificationsData(this.specifications);
    },
});
</script>
