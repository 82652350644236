<template>
    <p class="selected-parts-amount">
        <fmmp-i18n text="Max 10 items can be selected at a time." />
        {{ getSelectedPartsQuantity() }}
    </p>
</template>

<script>
export default Vue.component("driv-gpi-selected-parts-amount", {
    props: {
        partsAmount: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        getSelectedPartsQuantity() {
            return Vue.i18n.get("{0} out of 10 selected", [this.partsAmount], "parts amount");
        },
    },
});
</script>
