var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "driv-interchanges-tab-wrapper" }, [
    _c("table", { staticClass: "driv-interchanges-tab" }, [
      _c("thead", { staticClass: "driv-interchanges-tab-head" }, [
        _c("tr", { staticClass: "driv-interchanges-tab-head-row" }, [
          _vm.isGurusProductInsights
            ? _c("th", { staticClass: "driv-interchanges-tab-head-cell" })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "th",
            { staticClass: "driv-interchanges-tab-head-cell" },
            [_c("fmmp-i18n", { attrs: { text: _vm.columns.manufacturer } })],
            1
          ),
          _vm._v(" "),
          _c(
            "th",
            { staticClass: "driv-interchanges-tab-head-cell" },
            [_c("fmmp-i18n", { attrs: { text: _vm.columns.partNumber } })],
            1
          ),
          _vm._v(" "),
          _c(
            "th",
            { staticClass: "driv-interchanges-tab-head-cell" },
            [_c("fmmp-i18n", { attrs: { text: _vm.columns.ownPartNumber } })],
            1
          ),
          _vm._v(" "),
          _c(
            "th",
            { staticClass: "driv-interchanges-tab-head-cell" },
            [_c("fmmp-i18n", { attrs: { text: _vm.columns.productType } })],
            1
          ),
          _vm._v(" "),
          _vm.isGurusProductInsights
            ? _c(
                "th",
                { staticClass: "driv-interchanges-tab-head-cell" },
                [_c("fmmp-i18n", { attrs: { text: _vm.columns.partNotes } })],
                1
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        { staticClass: "driv-interchanges-tab-body" },
        _vm._l(_vm.products, function(product, index) {
          return _c(
            "tr",
            { key: index, staticClass: "driv-interchanges-tab-row" },
            [
              _vm.isGurusProductInsights
                ? _c("td", { staticClass: "driv-interchanges-tab-cell" }, [
                    _c("input", {
                      staticClass: "select-part",
                      attrs: {
                        type: "checkbox",
                        name: _vm.checkboxAttrName.interchange_products,
                        id: _vm.generateCustomId(product),
                        disabled: _vm.isReadOnly
                      },
                      domProps: {
                        checked: _vm.isPartSelected(
                          _vm.generateCustomId(product)
                        )
                      },
                      on: {
                        click: function($event) {
                          return _vm.togglePartSelection($event)
                        }
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "driv-interchanges-tab-cell" },
                [
                  _c("fmmp-i18n", {
                    staticClass: "driv-interchanges-tab-cell-heading",
                    attrs: { text: _vm.columns.manufacturer }
                  }),
                  _vm._v(
                    "\n                    " +
                      _vm._s(product.brand_name) +
                      "\n                "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "driv-interchanges-tab-cell" },
                [
                  _c("fmmp-i18n", {
                    staticClass: "driv-interchanges-tab-cell-heading",
                    attrs: { text: _vm.columns.partNumber }
                  }),
                  _vm._v(
                    "\n                    " +
                      _vm._s(product.part_number) +
                      "\n                "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "driv-interchanges-tab-cell" },
                [
                  _c("fmmp-i18n", {
                    staticClass: "driv-interchanges-tab-cell-heading",
                    attrs: { text: _vm.columns.ownPartNumber }
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "driv-interchanges-tab-part-number",
                      attrs: { rel: "noopener noreferrer", target: "_blank" },
                      on: {
                        click: function($event) {
                          return _vm.getPartDetail(
                            product.own_part_number,
                            product.own_brand_code,
                            _vm.detailsPath
                          )
                        }
                      }
                    },
                    [_vm._v(_vm._s(product.own_part_number))]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "driv-interchanges-tab-cell" },
                [
                  _c("fmmp-i18n", {
                    staticClass: "driv-interchanges-tab-cell-heading",
                    attrs: { text: _vm.columns.productType }
                  }),
                  _vm._v(
                    "\n                    " +
                      _vm._s(product.part_name) +
                      "\n                "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.isGurusProductInsights
                ? _c(
                    "td",
                    {
                      class: [
                        "driv-interchanges-tab-cell part-notes",
                        _vm.getPartNotesClass(product)
                      ]
                    },
                    [
                      _c("fmmp-i18n", {
                        staticClass: "driv-interchanges-tab-cell-heading",
                        attrs: { text: _vm.columns.partNotes }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }