<template>
    <div>
        <div class="default-filter-container">
            <div>
                <h5 class="title">
                    {{ colHeaderName }}
                </h5>
            </div>
            <select class="default-filter" @change="handleDefaultFilterChange($event)">
                <option v-for="option in optionsList" :key="option"
                    :class="['specification-filter__option', { selected: option === selectedOption }]" :value="option"
                    :selected="option === selectedOption">
                    {{ getTranslation(option) }}
                </option>
            </select>
        </div>
        <div>
            <h4 class="filter-separator">{{ getTranslation('or') }}</h4>
        </div>
        <form class="range-filter-form" @submit.prevent.stop="applyFilter">
            <div class="range-filter-form__fields">
                <h5 class="title">{{ getTranslation('Refine Range') }}</h5>
                <div class="values-interval">
                    <div>
                        <input type="number" step="0.001" :placeholder="inputPlaceholders.start"
                            v-model="rangeFilter.value_from" @input="updateRangeFilter" />
                    </div>
                    <div>
                        <input type="number" step="0.001" :placeholder="inputPlaceholders.end"
                            v-model="rangeFilter.value_to" @input="updateRangeFilter" />
                    </div>
                </div>
            </div>
            <fmmp-error-message v-if="errorMessage" :class="{ 'range-filter-form__error-message': true }"
                message="Please enter start or end value." />
            <div class="range-filter-form__buttons">
                <button class="button-main" type="button" @click="clearFilters">
                    {{ this.getTranslation('Clear') }}
                </button>
                <button class="button-main colored" type="submit">
                    {{ this.getTranslation('Apply') }}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { getTranslation } from "../../../../common/partFinderCorporate.helpers";
import { getHashParams } from "../helpers";
import { coverFuncWithAnalytics } from "./specification.helpers";
import { SPECIFICATION_PROPERTIES, APPLY_RANGE_FILTER } from "../constants";
import { ref } from 'vue';

const ALL_OPTION = "all";

export default Vue.component("driv-range-filter-block", {
    data() {
        return {
            rangeFilter: {
                value_from: '',
                value_to: ''
            },
            rangeFilters: [],
            errorMessage: false,
            colHeaderName: this.params?.colDef?.headerName,
            selectedOption: ALL_OPTION,
        };
    },
    computed: {
        isGurusProductInsights() {
            return window.brand === "gpi";
        },
        columnName() {
            let colId = "";
            if (this.params && this.params.colDef) {
                const name = this.params.colDef.headerName || "";
                colId = this.params.numericColumns.find(obj => obj.label === name);
                this.colHeaderName = this.params?.colDef?.headerName;
            }

            return colId;
        },
        optionsList() {
            const { options, column, isNumeric } = this.params || {};
            /* ColDef contains updated filters content, but options from filter params 
                look like they're never updated, so they are used as a fallback */
            const relevantOptions = column?.colDef?.filterParams?.options || options;
            if (!relevantOptions?.length) return [];

            const sortedOptions = relevantOptions.sort(isNumeric ? (a, b) => a - b : undefined);
            return [ALL_OPTION, ...sortedOptions];
        },
        inputPlaceholders() {
            return this.isGurusProductInsights
                ? {
                    column: "Select",
                    start: this.getTranslation("Insert"),
                    end: this.getTranslation("Insert"),
                }
                : {
                    column: "Select column",
                    start: this.getTranslation("Start value"),
                    end: this.getTranslation("End value"),
                };
        },
    },
    watch: {
        selectedOption(option) {

            const colId = this.params?.colDef?.field || "";
            const [name, uom] = colId.split("_");

            this.params?.filterChangedCallback({
                selectedFilter: {
                    name,
                    value: option === ALL_OPTION ? "" : option,
                    ...(uom ? { uom } : {}),
                },
                colId,
            });
        },
    },
    mounted() {
        this.params.api.addEventListener('filterOpened', this.handleFilterOpened);
    },
    methods: {
        getTranslation,
        handleDefaultFilterChange(e) {
            if (e.target) {
                this.selectedOption = e.target.value;
            }
        },
        handleFilterOpened(event) {
            // Check if the filterOpened event is specific to the current column
            if (event.column === this.params?.column) {
                // Call the updateRangeFilter method or perform any desired action
                this.updateRangeFilter();
            }
        },
        updateFilterValue(columnName, propertyName, value) {
            const filter = this.rangeFilters.find(filter => filter.name === columnName);
            if (filter) {
                filter[propertyName] = value;
            }
        },
        updateRangeFilter() {
            const currentColumnIdentifier = this.params?.colDef?.field || '';
            const rangeFilter = this.rangeFilters.find(filter => filter.name.value + (filter.name.uom ? `_${filter.name.uom}` : "") === currentColumnIdentifier);
            if (rangeFilter) {
                rangeFilter.value_from = this.rangeFilter.value_from;
                rangeFilter.value_to = this.rangeFilter.value_to;
                this.rangeFilter = { ...rangeFilter };
            }
        },
        toggleRangeFilterBlock() {
            coverFuncWithAnalytics({
                func: () => {
                    this.isShown = !this.isShown;
                },
                eventAction: "click-range-filter",
            });
        },
        generateInitialFilters() {
            const allFilters = getHashParams();
            const colId = this.columnName;

            if ('attribute_range_filters' in allFilters) {
                if (allFilters.attribute_range_filters.length > 0) {
                    this.rangeFilters = allFilters.attribute_range_filters;
                }
            }

            const existingFilter = this.rangeFilters.find(filter => filter.name.name + (filter.name.uom ? `_${filter.name.uom}` : "") === colId.value + (colId.uom ? `_${colId.uom}` : ""));

            if (existingFilter) {
                // Update the existing filter's values
                this.rangeFilter.value_from = existingFilter.value_from
                this.rangeFilter.value_to = existingFilter.value_to;
                return existingFilter;
            } else {
                const newFilter = {
                    name: colId,
                    value_from: '',
                    value_to: ''
                };
                this.rangeFilters.push(newFilter);
                return newFilter;
            }
        },
        setFilterValue(columnName, propertyName, value) {
            const filter = this.rangeFilters.find(filter => filter.name === columnName);
            if (filter) {
                filter[propertyName] = value;
            }
        },
        getFilterValue(columnName, propertyName) {
            const filter = this.rangeFilters.find(filter => filter.name === columnName);
            return filter ? filter[propertyName] : null;
        },
        clearFilters() {
            const colName = this.colHeaderName;
            const allFilters = getHashParams();
            this.checkIfSomeDataWasNotEntered(this.rangeFilters);
            const { attribute_range_filters, ...filters } = allFilters;
            let filteredRange = [];
            if (attribute_range_filters && attribute_range_filters.length) {
                filteredRange = attribute_range_filters.filter(selectedFilter => {
                    const currentCol = selectedFilter.name.label;
                    return colName !== currentCol;
                });
            }
            const filterList = {
                ...filters,
                attribute_range_filters: filteredRange,
            };

            // Clear values of other inputs
            this.selectedOption = ALL_OPTION;

            if (!this.errorMessage) {
                // Reset the rangeFilter object to clear the input values
                this.rangeFilter = {
                    value_from: '',
                    value_to: ''
                };

                window.location.hash = Qs.stringify(filterList);
                this.errorMessage = false;
                this.rangeFilters = this.rangeFilters.filter(obj => obj.name.label !== this.colHeaderName);
                this.getActualFilters();
                this.params.api.refreshHeader();
            }
        },
        checkIfSomeDataWasNotEntered(rangeFilters) {
            this.errorMessage = rangeFilters.some((filter) => {
                if (!filter.name) {
                    return false;
                }
                return filter.value_from.trim() === '' && filter.value_to.trim() === '';
            });
        },
        deleteNullishPropsFromRangeFilters() {
            return this.rangeFilters.filter(filter => {
                const { name, value_from, value_to } = filter;
                return name && (value_from || value_to);
            });
        },
        addRangeFiltersToHash() {
            const allFilters = getHashParams();
            this.updateRangeFilter();
            const updatedRangeFilters = this.deleteNullishPropsFromRangeFilters();
            const updatedFilters = {
                ...allFilters,
                [SPECIFICATION_PROPERTIES.ATTRIBUTE_RANGE_FILTERS]: updatedRangeFilters,
            };
            let filterModel = [];
            if (updatedFilters.attribute_range_filters) {
                const selectedRangeFilter = updatedFilters.attribute_range_filters.map(filter => {
                    filter.name.name = filter.name.value;
                    return filter.name;
                });
                filterModel =
                    typeof updatedFilters.attribute_filters !== "undefined"
                        ? [...updatedFilters.attribute_filters, ...selectedRangeFilter]
                        : [...selectedRangeFilter];
            } else {
                filterModel = [...updatedFilters.attribute_filters];
            }
            const headerElements = document.querySelectorAll(".ag-header-cell");
            headerElements.forEach(element => {
                const fieldEle = element.getAttribute("col-id");
                if (filterModel.length) {
                    filterModel.forEach(filter => {
                        const field = filter.value + (filter.uom ? `_${filter.uom}` : "");
                        if (field === fieldEle) {
                            // adds the active class to the filter
                            setTimeout(() => element.classList.add('ag-header-cell-filtered'), 0);
                        } else {
                            element.classList.remove('ag-header-cell-filtered');
                        }
                    });
                } else {
                    element.classList.remove('ag-header-cell-filtered');
                }
            });

            window.location.hash = Qs.stringify(updatedFilters);
        },
        applyFilter() {
            this.checkIfSomeDataWasNotEntered(this.rangeFilters);
            if (!this.errorMessage) {
                const colName = this.colHeaderName;

                // Check if an identical filter already exists in rangeFilters
                const existingFilterIndex = this.rangeFilters.findIndex(
                    filter =>
                        filter.name.label === colName &&
                        filter.value_from === this.rangeFilter.value_from &&
                        filter.value_to === this.rangeFilter.value_to
                );

                if (existingFilterIndex !== -1) {
                    // If the identical filter exists, update its values
                    this.rangeFilters[existingFilterIndex].value_from = this.rangeFilter.value_from;
                    this.rangeFilters[existingFilterIndex].value_to = this.rangeFilter.value_to;
                }

                coverFuncWithAnalytics({
                    func: () => { this.addRangeFiltersToHash(); },
                    eventAction: APPLY_RANGE_FILTER,
                    rangeFilters: this.rangeFilters,
                });
            }
        },
        getActualFilters() {
            const allFilters = getHashParams();
            const initialFilters = this.generateInitialFilters();
            const attributeRangeFilters = [
                ...(allFilters[SPECIFICATION_PROPERTIES.ATTRIBUTE_RANGE_FILTERS] || []),
                ...(initialFilters ? [initialFilters] : []),
            ].reduce((accumulator, currentFilter) => {
                // Check if the current filter already exists in the accumulator array
                const existingIndex = accumulator.findIndex(filter => {
                    return (
                        filter.name.value === currentFilter.name.value &&
                        filter.name.label === currentFilter.name.label &&
                        filter.name.uom === currentFilter.name.uom &&
                        filter.value_from === currentFilter.value_from &&
                        filter.value_to === currentFilter.value_to
                    );
                });

                if (existingIndex === -1) {
                    // Filter does not exist, add it to the accumulator array
                    accumulator.push(currentFilter);
                } else {
                    // Filter exists, update its values in the accumulator array
                    accumulator[existingIndex].value_from = currentFilter.value_from;
                    accumulator[existingIndex].value_to = currentFilter.value_to;
                }

                return accumulator;
            }, []);
            this.rangeFilters = attributeRangeFilters.length === 1 ? [...attributeRangeFilters] : attributeRangeFilters;
        },
        onHashChange() {
            const hashValue = getHashParams();
            if (!Qs.stringify(hashValue)) {
                this.getActualFilters();
            }
        },
        getModel() {
            return { value: this.selectedOption };
        },
        setModel(model) {
            this.selectedOption = model?.value ?? ALL_OPTION;
        },
    },
    created() {
        this.getActualFilters();
        window.onhashchange = this.onHashChange;
    },
});
</script>
