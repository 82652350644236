<template>
    <tr class="quick-details__row">
        <td :class="{ loaded: !isLoading }" colspan="10">
            <div v-if="isLoading" class="three-quarters-loader">
                <fmmp-i18n text="loading..." />
            </div>
            <div v-else class="quick-details__container">
                <i
                    class="fa fa-plus-circle quick-details__close-icon"
                    @click="toggleQuickDetails"
                />
                <div v-if="isError">
                    <fmmp-i18n text="Something went wrong. Please, try again later." />
                </div>
                 <driv-tabs :activeTabIndex="selectedTabIndex" v-else>
                    <driv-tab 
                        :name="getTranslation('Features')" 
                        :selected="true"
                        :tabsOnMobile="true">
                        <div class="quick-details__features-tab">
                            <driv-part-detail-page-logo
                                :partDetail="partDetail"
                            ></driv-part-detail-page-logo>
                            <p class="quick-details__part-name">
                                {{ partDetail.part_type }}
                                <span
                                    v-if="partDetail.sub_brand_name"
                                    class="quick-details__sub-brand"
                                    >{{ partDetail.sub_brand_name }}</span
                                >
                            </p>
                            <driv-part-detail-page-content
                                :partDetail="partDetail"
                            ></driv-part-detail-page-content>
                        </div>
                    </driv-tab>
                    <driv-tab
                        v-if="partDetail.part_attributes && partDetail.part_attributes.length"
                        :name="getTranslation('Specifications')"
                        :tabsOnMobile="true"
                    >
                        <driv-part-detail-page-specifications
                            :partAttributes="partDetail.part_attributes"
                            :partType="partDetail.part_type"
                        ></driv-part-detail-page-specifications>
                    </driv-tab>
                    <driv-tab
                        v-if="partDetail.kits && partDetail.kits.length"
                        :name="getTranslation('Contents')"
                        :tabsOnMobile="true"
                    >
                        <driv-part-detail-page-contents
                            :kits="partDetail.kits"
                            :detailsPagePath="detailsPath"
                            :showQuickDetails="showQuickDetails"
                        ></driv-part-detail-page-contents>
                    </driv-tab>
                </driv-tabs>
            </div>
        </td>
    </tr>
</template>

<script>
import { getTranslation } from "../../../../common/partFinderCorporate.helpers";
import { warningAttrValue } from "../../../../driv-part-detail-page/common/partDetailsPage.constants";
import { SAVED_QUICK_DETAILS_PART_KEY } from "../constants";

export default Vue.component("driv-quick-details-grid-view", {
    data() {
        return {
            partDetail: {},
            isLoading: true,
            isError: false,
            selectedTabIndex: 0,
        };
    },
    props: {
        product: {
            type: Object,
            default() {
                return {};
            },
        },
        toggleQuickDetails: {
            type: Function,
            default: () => {},
        },
        detailsPath: {
            type: String,
            default: "",
        },
        showQuickDetails: {
            type: String,
            default: "",
        },
    },
    methods: {
        getTranslation,
    },
    mounted() {
        const decodedPartNumber = decodeURIComponent(this.product.part_number);
        const decodedBrandCode = decodeURIComponent(this.product.brand);

        Vue.CatalogApi.CatalogApiService.getPartDetail({
            part_number: decodedPartNumber,
            brand_code: decodedBrandCode,
            lite: true,
        })
            .then((response) => {
                this.partDetail = response;
                this.partDetail.part_attributes = response.part_attributes?.filter(
                    (attr) => attr.attribute !== warningAttrValue,
                );
            })
            .catch(() => {
                this.isError = true;
            })
            .finally(() => {
                this.isLoading = false;
            });
    },
    created() {
            const savedShowQuickDetails = sessionStorage.getItem(SAVED_QUICK_DETAILS_PART_KEY);
            if (savedShowQuickDetails) {
                this.selectedTabIndex = this.partDetail.part_attributes?.length ? 2 : 1;
            }
        },
    destroyed() {
        const savedShowQuickDetails = sessionStorage.getItem(SAVED_QUICK_DETAILS_PART_KEY);
        if (savedShowQuickDetails) {
            sessionStorage.removeItem(SAVED_QUICK_DETAILS_PART_KEY);
            this.selectedTabIndex = 0;
        }
    },
});
</script>
