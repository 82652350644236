<template>
    <fmmp-modal v-if="notificationModal" rootClass="driv-notification-modal" @close="closeModal">
        <div class="modal-header"></div>
        <template slot="title">
            <fmmp-i18n :text="modalTitle" />
        </template>
        <slot></slot>
    </fmmp-modal>
</template>

<script>
import {
    createOverlay,
    removeOverlay,
    getTranslation,
} from "../../../../common/partFinderCorporate.helpers";

export default Vue.component("driv-notification-modal", {
    props: {
        notificationModal: {
            type: Boolean,
            default: false,
        },
        modalTitle: {
            type: String,
            default: "",
        },
    },
    watch: {
        notificationModal(val) {
            if (val) {
                createOverlay(this.closeModal);
            } else {
                removeOverlay();
            }
        },
    },
    methods: {
        getTranslation,
        closeModal() {
            this.$emit("updateModalState", false);
        },
    },
});
</script>
