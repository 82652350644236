import { getInitialFieldConfig } from "./helpers";

export const FIELDS = {
    YEAR: "Year",
    MAKE: "Makes",
    MODEL: "Models",
    ENGINE: "Engines",
};

export const BASE_PROPS = {
    BASE: "Base",
    BASE_ID: "BaseVehicleID",
};

export const SPEC_PROPS = {
    SPEC: "Spec",
    APPS: "Applications",
};

export const INITIAL_FIELDS_CONFIG = {
    [FIELDS.YEAR]: getInitialFieldConfig("Year"),
    [FIELDS.MAKE]: getInitialFieldConfig("Make"),
    [FIELDS.MODEL]: getInitialFieldConfig("Model"),
    [FIELDS.ENGINE]: getInitialFieldConfig("Engine"),
};

export const INITIAL_CONFIG_FOR_URL_CHANGE = {
    searchParams: {},
    options: [],
};

export const EMPTY_ITEM = {
    value: "",
    label: "---",
};
